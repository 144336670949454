import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import Navbar from "../../Layouts/Navbar";
import Footer from "../../Layouts/Footer";
import LeftSideBar from "../../Layouts/LeftSideBar";
import { toast } from "react-toastify";

const PositiveEnquiry = () => {
  const [listPageData, setListPageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [status, setStatus] = useState(0);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadContent();
  }, []);

  const loadContent = async () => {
    await axios
      .get(`${API_BASE_URL}/pasitive-enquiry-list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setListPageData(response.data.result.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNegative = (id) => {
    const result = window.confirm(
      "Do you want to make this enquiry Negative ?"
    );

    if (result) {
      axios
        .get(`${API_BASE_URL}/negative-enquiry/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === "200") {
            toast.success("Enquiry Added To Negative List successfully");
            axios
              .get(`${API_BASE_URL}/list-enquiry`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((response) => {
                setListPageData(response.data.result.reverse());
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    }
  };

  const handleDelete = (id) => {
    const result = window.confirm("Do you want to delete ?");

    if (result) {
      axios
        .get(`${API_BASE_URL}/enquiry-delete/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === "200") {
            toast.success("Delete successully");
            axios
              .get(
                `${API_BASE_URL}/list-enquiry`,

                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                setListPageData(response.data.result.reverse());
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Positive Enquiry </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item active">Manage Enquiry</li>
                      <li className="breadcrumb-item active">
                        Positive Enquiry
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">
                      Positive Enquiry List ({listPageData?.length})
                    </h4>
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th className="align-middle">Name</th>
                            <th className="align-middle">Email</th>
                            <th className="align-middle">Mobile</th>
                            <th className="align-middle">Message</th>
                            <th className="align-middle">Follow Date</th>
                            <th className="align-middle">Enquiry Status</th>
                            <th className="align-middle">Status</th>
                            <th className="align-middle">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listPageData?.map((item) => (
                            <>
                              <tr>
                                <td>{item.name}</td>

                                <td>{item.email}</td>
                                <td>
                                  {item.mobile}
                                  <button className="btn btn-sm btn-success ms-2">
                                    <i className="fa fa-phone-alt"></i>
                                  </button>
                                </td>
                                <td>{item.message}</td>
                                <td>{item.follow_up_date}</td>
                                <td>
                                  {item.is_verify == 0 ? (
                                    <span className="badge badge-pill badge-soft-danger font-size-11">
                                      Unverified
                                    </span>
                                  ) : (
                                    <span className="badge badge-pill badge-soft-success font-size-11">
                                      Verified
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {item.status == 1 ? (
                                    <span className="badge badge-pill badge-soft-danger font-size-11">
                                      Negative
                                    </span>
                                  ) : item.status == 2 ? (
                                    <span className="badge badge-pill badge-soft-success font-size-11">
                                      Positive
                                    </span>
                                  ) : (
                                    <span className="badge badge-pill badge-soft-warning font-size-11">
                                      Pending
                                    </span>
                                  )}
                                </td>

                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-warning btn-sm btn-rounded waves-effect waves-light ms-2"
                                    onClick={() => {
                                      handleNegative(item.id);
                                    }}
                                  >
                                    <i className="fa fa-thumbs-down"></i>
                                  </button>
                                  {/* <Link
                                    // to={`/crm/managepage/editpage/${id}`}
                                    type="button"
                                    className="btn btn-primary btn-sm btn-rounded waves-effect waves-light ms-2"
                                  >
                                    <i className="fa fa-pen"></i>
                                  </Link> */}
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm btn-rounded waves-effect waves-light ms-2"
                                    onClick={() => {
                                      handleDelete(item.id);
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PositiveEnquiry;
