import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import { encode } from "html-entities";

const AddContentPage = () => {
  const [formData, setFormData] = useState({
    description: "",
  });
  const [image, setImage] = useState();
  const navigate = useNavigate();
  const [pagename, setPageName] = useState("");
  const [urlValue, setUrlValue] = useState("");
  const [parentLinkError, setParentLinkError] = useState("");
  const [pageNameError, setPageNameError] = useState("");
  const [pageTitleError, setPageTitleError] = useState("");
  const [shortDescriptionError, setShortDescriptionError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [pageSliderError, setPageSliderError] = useState("");
  const [imageError, setImageError] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [managePageData, setManagePageData] = useState();
  const [pageError, setPageError] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));

  let hasError = false;
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    axios
      .get(`${API_BASE_URL}/list-pages`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        setManagePageData(response.data.result.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleEdiorChangeShortDescription = (content, editor) => {
    setFormData({ ...formData, shortdescription: content });
  };

  const handleEditorChangePageSlider = (content, editor) => {
    setFormData({ ...formData, pagesliderdescription: content });
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    formData.pageurl = urlValue;
    if (!formData.parentlink) {
      setParentLinkError("Please select parentlink");
      hasError = true;
    } else {
      setParentLinkError("");
    }
    // if (!formData.pagename) {
    //   setPageNameError("Please enter Page Name");
    //   hasError = true;
    // } else {
    //   setPageNameError("");
    // }
    // if (!formData.pagetitle) {
    //   setPageTitleError("Please enter the title");
    //   hasError = true;
    // } else {
    //   setPageTitleError("");
    // }
    // if (!formData.shortdescription) {
    //   setShortDescriptionError("Please enter the short description ");
    //   hasError = true;
    // } else {
    //   setShortDescriptionError("");
    // }
    if (!formData.description) {
      setDescriptionError("Please enter the description");
      hasError = true;
    } else {
      setDescriptionError("");
    }
    // if (!formData.pagesliderdescription) {
    //   setPageSliderError("Please enter the page slider description");
    //   hasError = true;
    // } else {
    //   setPageSliderError("");
    // }
    if (!formData.image) {
      setImageError("Please select the image");
      hasError = true;
    } else {
      setImageError("");
    }

    const form = new FormData();
    form.append("image", image);
    form.append("page_id", formData.parentlink);
    form.append("page_content", formData.description);

    if (!hasError) {
      axios
        .post(`${API_BASE_URL}/create-page-content`, form, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status === "200") {
            navigate("/crm/managecontentpage");
            toast.success("Added successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          setPageError(error.response.data.result);
        });
    }

    console.log(formData, "form");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (name === "image") {
      setImage(event.target.files[0]);
    }

    if (name == "pagename") {
      setPageName(value);

      const url = value
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .replace(/\s+/g, "-");
      setUrlValue(url);
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Add Content Page </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/crm/managecontentpage"}>
                          Manage Content Page
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Add Content Page
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Add Content Page</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Parent Link
                              </label>
                              <div className="col-lg-10">
                                <select
                                  className={
                                    parentLinkError
                                      ? " form-select is-invalid"
                                      : "form-control "
                                  }
                                  styles="max-width: 90px;"
                                  name="parentlink"
                                  onChange={handleInputChange}
                                >
                                  <option value="">Select an option</option>
                                  {managePageData?.map((item) => (
                                    <option value={item.id}>
                                      {item.page_name}
                                    </option>
                                  ))}
                                </select>
                                {parentLinkError && (
                                  <div className="text-danger">
                                    {parentLinkError}
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* <div className="form-group row mb-4">
                            <label
                              for="taskname"
                              className="col-form-label col-lg-2"
                            >
                              Page Name
                            </label>
                            <div className="col-lg-10">
                              <input
                                id="taskname"
                                name="pagename"
                                type="text"
                                value={pagename}
                                className={
                                  pageNameError
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                                placeholder="Enter Page Name..."
                                onChange={(e) => {
                                  handleInputChange(e);
                                }}
                              />
                              {pageNameError && (
                                <div className="text-danger">
                                  {pageNameError}
                                </div>
                              )}
                            </div>
                          </div> */}
                            {/* <div className="form-group row mb-4">
                            <label
                              for="taskname"
                              className="col-form-label col-lg-2"
                            >
                              Page Title
                            </label>
                            <div className="col-lg-10">
                              <input
                                id="taskname"
                                name="pagetitle"
                                type="text"
                                className={
                                  pageTitleError
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                                placeholder="Enter Page Title..."
                                onChange={handleInputChange}
                              />
                              {pageTitleError && (
                                <div className="text-danger">
                                  {pageTitleError}
                                </div>
                              )}
                            </div>
                          </div> */}
                            {/* <div className="form-group row mb-4">
                            <label
                              for="taskname"
                              className="col-form-label col-lg-2"
                            >
                              Page Url
                            </label>
                            <div className="col-lg-10">
                              <input
                                id="taskname"
                                name="pageurl"
                                value={urlValue}
                                readOnly
                                onChange={handleInputChange}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div> */}
                            {/* <div className="form-group row mb-4">
                            <label className="col-form-label col-lg-2">
                              Short Description
                            </label>
                            <div className="col-lg-10">
                              <Editor
                                onEditorChange={
                                  handleEdiorChangeShortDescription
                                }
                                init={{
                                  plugins: "table",
                                  table_default_attributes: {
                                    border: "5",
                                  },
                                }}
                              />
                              {shortDescriptionError && (
                                <div className="text-danger">
                                  {shortDescriptionError}
                                </div>
                              )}
                            </div>
                          </div> */}
                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Description
                              </label>
                              <div className="col-lg-10">
                                <Editor
                                  onEditorChange={handleEditorChangeDescription}
                                  init={{
                                    plugins:
                                      "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    table_default_attributes: {
                                      border: "5",
                                    },
                                  }}
                                />
                                {descriptionError && (
                                  <div className="text-danger">
                                    {descriptionError}
                                  </div>
                                )}
                              </div>
                            </div>

                            {/* <div className="form-group row mb-4">
                            <label className="col-form-label col-lg-2">
                              Page Slider Description
                            </label>
                            <div className="col-lg-10">
                              <Editor
                                onEditorChange={handleEditorChangePageSlider}
                                init={{
                                  plugins: "table",
                                  table_default_attributes: {
                                    border: "5",
                                  },
                                }}
                              />
                              {pageSliderError && (
                                <div className="text-danger">
                                  {pageSliderError}
                                </div>
                              )}
                            </div>
                          </div> */}

                            <div className="inner-repeater mb-4">
                              <div
                                data-repeater-list="inner-group"
                                className="inner form-group mb-0 row"
                              >
                                <label className="col-form-label col-lg-2">
                                  Image
                                </label>
                                <div
                                  data-repeater-item
                                  className="inner col-lg-10 ms-md-auto"
                                >
                                  <div className="mb-3 row align-items-center">
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-md-0">
                                        <input
                                          className={
                                            imageError
                                              ? "form-control is-invalid"
                                              : "form-control "
                                          }
                                          name="image"
                                          type="file"
                                          accept="image/*"
                                          onChange={(e) => {
                                            handleImageChange(e);
                                            handleInputChange(e);
                                          }}
                                        />
                                        {imageError && (
                                          <div className="text-danger">
                                            {imageError}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-20">
                                        <div>
                                          {imagePreview && (
                                            <img
                                              src={imagePreview}
                                              alt="Preview"
                                              width="200"
                                              height="200"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}

                            <button type="submit" className="btn btn-primary">
                              Add Content Page
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddContentPage;
