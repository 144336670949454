import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";

const EditFaq = () => {
  const [formData, setFormData] = useState({
    pagename: "",
    question: "",
    answer: "",
  });
  const { id } = useParams();
  const { pagename, question, answer } = formData;
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [pageError, setPageError] = useState("");
  const [pageName, setPageNameError] = useState("");
  const [questionError, setQuestionError] = useState("");
  const [answerError, setAnswerError] = useState("");
  const navigate = useNavigate();
  let hasError = false;

  useEffect(() => {
    setToken(localStorage.getItem("token"));

    ViewPage();
  }, []);

  const ViewPage = async () => {
    const res = await axios.get(`${API_BASE_URL}/view-faq/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.data.status == 200) {
      setFormData({
        ...formData,
        pagename: res.data.result.page_name,
        question: res.data.result.question,
        answer: res.data.result.answer,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData, "form");
    // if (!formData.pagename) {
    //   setPageNameError("Please enter name ");
    //   hasError = true;
    // } else {
    //   setPageNameError("");
    // }

    if (!formData.question) {
      setQuestionError("Please  enter question ");
      hasError = true;
    } else {
      setQuestionError("");
    }
    if (!formData.answer) {
      setAnswerError("Please enter answer ");
      hasError = true;
    } else {
      setAnswerError("");
    }

    if (!hasError) {
      axios
        .post(
          `${API_BASE_URL}/update-faq`,
          {
            page_name: formData.pagename,
            page_url: formData.pagename,
            question: formData.question,
            answer: formData.answer,
            id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status === "200") {
            navigate("/crm/managefaq");
            toast.success("Addded successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          setPageError(error.response.data.result);
        });
    }
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, answer: content });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Edit Faq </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/crm/managefaq"}>Manage Faq</Link>
                      </li>
                      <li className="breadcrumb-item active">Edit Faq</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Edit Faq</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            {/* <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Page Name
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="pagename"
                                  type="text"
                                  value={pagename}
                                  className={
                                    pageName
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter page name..."
                                  onChange={handleInputChange}
                                />
                                {pageName && (
                                  <div className="text-danger">{pageName}</div>
                                )}
                              </div>
                            </div> */}

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Question
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="question"
                                  type="text"
                                  value={question}
                                  className={
                                    questionError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter page question ..."
                                  onChange={handleInputChange}
                                />
                                {questionError && (
                                  <div className="text-danger">
                                    {questionError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Answer
                              </label>
                              {answer && (
                                <div className="col-lg-10">
                                  <Editor
                                    onEditorChange={
                                      handleEditorChangeDescription
                                    }
                                    init={{
                                      plugins:
                                        "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                      table_default_attributes: {
                                        border: "5",
                                      },
                                    }}
                                    initialValue={answer}
                                  />

                                  {answerError && (
                                    <div className="text-danger">
                                      {answerError}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}
                            <button type="submit" className="btn btn-primary">
                              Edit Faq
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFaq;
