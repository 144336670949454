import React, { useState, useEffect } from "react";
import api from "../components/cms/api";
import axios from "axios";
import { API_BASE_URL } from "../components/config";
import Navbar from "./Layouts/Navbar";
import LeftSideBar from "./Layouts/LeftSideBar";
import Footer from "./Layouts/Footer";

const Dashboard = () => {
  const [listPageData, setListPageData] = useState();
  const [mangeSlider, setMangeSlider] = useState();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [seoPage, setSeoPage] = useState();
  const [faqPage, setFaqPage] = useState();
  const [recentCustomer,setRecentCustomer]=useState([]);
  const [recentTransaction,setRecentTransaction]=useState([]);
  const [dashboard,setDashboard]=useState({
    total_customer:0,
    total_active_customer:0,
    total_inactive_customer:0,
    total_enquiry:0,
    total_pasitve_enquiry:0,
    total_negative_enquiry:0
  });

  const {total_customer,total_active_customer,total_inactive_customer,total_enquiry,total_pasitve_enquiry,total_negative_enquiry}=dashboard;

  useEffect(() => {
    setToken(localStorage.getItem("token"));
      recentcustomer();
      recenttransaction();
      loadCustomer();
  }, []);

  /*-------------recent customer detais-------*/

  const recentcustomer=async()=>{
      try{
          const recenthistory = await axios.get(API_BASE_URL+'/get-recent-customer',{headers: {Authorization: `Bearer ${token}`}});
          const recentdata=JSON.parse(JSON.stringify(recenthistory.data));
          setRecentCustomer(recentdata.result.companies);
      }catch(e){
        return false;
      }
  }

  /*-----------recent transaction details----------*/

  const recenttransaction=async()=>{
      try{
          const recenthistory = await axios.get(API_BASE_URL+'/get-recent-transaction',{headers: {Authorization: `Bearer ${token}`}});
          const recentdata=JSON.parse(JSON.stringify(recenthistory.data));
          setRecentTransaction(recentdata.result.transactions);
      }catch(e){
        return false;
      }
  }

  /*--------------dashboard calculation details---*/

  const loadCustomer = async() => {
    try{
      let total_enq=0;
      let total_neg_enq=0;
      let total_pasitive_enq=0;
      let total_cust=0;
      let total_act_cust=0;
      let total_inact_cust=0;
      const company_data = await axios.get(`${API_BASE_URL}/manage-company`, {headers: {Authorization: `Bearer ${token}`}});
      const js_data = JSON.parse(JSON.stringify(company_data.data));

      /*----------enquiry dashboard--------*/
      const enquiry_data = await axios.get(`${API_BASE_URL}/list-enquiry`, {headers: {Authorization: `Bearer ${token}`}});
      const js_enq_data = JSON.parse(JSON.stringify(enquiry_data.data));
    
      js_enq_data.result.map((item)=>{
        total_enq++;
      });

      const enquiry_neg_data = await axios.get(`${API_BASE_URL}/negative-enquiry-list`, {headers: {Authorization: `Bearer ${token}`}});
      const js_neg_enq_data = JSON.parse(JSON.stringify(enquiry_neg_data.data));
    
      js_neg_enq_data.result.map((item)=>{
        total_neg_enq++;
      });

      const enquiry_pasitive_data = await axios.get(`${API_BASE_URL}/pasitive-enquiry-list`, {headers: {Authorization: `Bearer ${token}`}});
      const js_pasitive_enq_data = JSON.parse(JSON.stringify(enquiry_pasitive_data.data));
    
      js_pasitive_enq_data.result.map((item)=>{
        total_pasitive_enq++;
      });
      
      js_data.result.company_list.map((item)=>{
          total_cust++;
          if(item.status==1){
            total_act_cust++;
          }

          if(item.status==0){
            total_inact_cust++;
          }

      })      
      setDashboard({...dashboard,total_customer:total_cust,
        total_active_customer:total_act_cust,
        total_inactive_customer:total_inact_cust, 
        total_enquiry:total_enq,
        total_negative_enquiry:total_neg_enq,  
        total_pasitve_enquiry:total_pasitive_enq    
      });
    }catch(e){
      return false;
    }
   
      
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Dashboard</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Dashboards</a>
                      </li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">    
                  
                    <div className="col-md-3">
                    <div className="card mini-stats-wid custom-website-dashboard">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium fs-5">Total Enquiry</p>
                            <h4 className="mb-0">{total_enquiry}</h4>
                          </div>                          
                        </div>
                      </div>
                    </div>
                  </div>

                    <div className="col-md-3">
                    <div className="card mini-stats-wid custom-website-dashboard">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium fs-5">Total Pasitive Enquiry</p>
                            <h4 className="mb-0">{total_pasitve_enquiry}</h4>
                          </div>                          
                        </div>
                      </div>
                    </div>
                  </div>

                    <div className="col-md-3">
                    <div className="card mini-stats-wid custom-website-dashboard">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium fs-5">Total Negative Enquiry</p>
                            <h4 className="mb-0">{total_negative_enquiry}</h4>
                          </div>                          
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="card mini-stats-wid custom-website-dashboard">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium fs-5">Total Customer</p>
                            <h4 className="mb-0">{total_customer}</h4>
                          </div>                          
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="card mini-stats-wid custom-website-dashboard">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium fs-5">Total Active Customer</p>
                            <h4 className="mb-0">{total_active_customer}</h4>
                          </div>                          
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="card mini-stats-wid custom-website-dashboard">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium fs-5">Total In-active Customer</p>
                            <h4 className="mb-0">{total_inactive_customer}</h4>
                          </div>                          
                        </div>
                      </div>
                    </div>
                  </div>

                            
            </div>

            {/*--------------card table---------*/}

            <div className="row">
                <div className="col-lg-6">
                    <div className="card">                       
                        <div className="card-body">
                           <div className="card-title mb-4">
                              <h5>Recent Customer</h5>
                          </div>
                            <div className="table-responsive">
                                <table className="table align-middle table-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                      recentCustomer && recentCustomer.map((item)=>(
                                        <>
                                          <tr>
                                            <td>{item.company_name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.mobile}</td>
                                            <td>
                                            {
                                              item.status==1?<>
                                                <span className="badge badge-pill badge-soft-success font-size-11">
                                                  Active
                                                </span>
                                              </>
                                              :<>
                                                <span className="badge badge-pill badge-soft-danger font-size-11">
                                                  Inactive
                                                </span>
                                              </>
                                            }
                                                
                                            </td>
                                        </tr>
                                        </>
                                        ))
                                    }
                                     
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card">                       
                        <div className="card-body">
                           <div className="card-title mb-4">
                              <h5>Recent Transaction</h5>
                          </div>
                            <div className="table-responsive">
                                <table className="table align-middle table-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Order ID</th>
                                            <th>Transaction ID</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                      recentTransaction && recentTransaction.map((item)=>(
                                        <>
                                           <tr>
                                            <td>{item.order_id}</td>
                                            <td>{item.transaction_id}</td>
                                            <td>{item.amount/100}</td>
                                            <td>
                                            {
                                              item.status==2?<>
                                                <span className="badge badge-pill badge-soft-success font-size-11">
                                                  Paid
                                                </span>
                                              </>
                                              :item.status==3?<>
                                                <span className="badge badge-pill badge-soft-danger font-size-11">
                                                  Failed
                                                </span>
                                              </>
                                              :<>
                                                <span className="badge badge-pill badge-soft-warning font-size-11">
                                                  pending
                                                </span>
                                              </>
                                            }
                                                
                                            </td>
                                          </tr>  
                                        </>
                                        ))
                                    }
                                                                            
                                      
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
