import React,{useState} from 'react';
const CustomerProfile=(props)=>{
	const backbtn=()=>{
		props.setIsView(false);
	}

	return(
			<>
				 <section id="aff_prof_sec">
		         <div className="container">
		         <div className="back-btn">
			<button type="button" className="btn btn-warning" onClick={backbtn}><i className="fa fa-angle-left me-1"></i> Back</button>
			</div>
		            <div className="new_aff_card">
		               <div className="row">
		                  <div className="col-md-4 col-lg-4 pl-0">
		                     <div className="card aff_prof_box">
		                        <div className="profl_detais pb-4">
		                           <div className="text-center">
		                              <img src="https://docsbizkit.com/admin/img/avatar.svg" className="img-thumbnail" />
		                           </div>
		                           <h3>{props.viewCompanyData && props.viewCompanyData.company_name}</h3>
		                           <h3>
		                             {props.viewCompanyData && props.viewCompanyData.is_install==1?<><button className="btn btn-success btn-sm">Active</button></>:<><button className="btn btn-danger btn-sm">Inactive</button></>} 
		                           </h3>
		                           <div className="aff_con_text">
		                              <p className="mt-3 mb-0">
		                                 <i className="fa fa-phone me-2"></i>
		                                 {props.viewCompanyData && props.viewCompanyData.mobile}
		                              </p>
		                              <p className="mt-3 mb-0">
		                                 <i className="fa fa-envelope me-2"></i>
		                                 <span>{props.viewCompanyData && props.viewCompanyData.email}</span>
		                              </p>
		                              {
		                              	props.viewCompanyData && props.viewCompanyData.address!=null ?
		                              	<>
		                              		 <p className="mt-3 mb-0">
				                                 <i className="fa fa-map-marker me-2"></i>
				                                 <span>{props.viewCompanyData && props.viewCompanyData.address}</span>
				                              </p>
		                              	</>
		                              	:null
		                              }
		                             
		                           </div>
		                        </div>
		                     </div>
		                  </div>
		                  <div className="col-md-4 col-lg-4">
		                     <div className="card aff_prof_box">
		                        <div className="">
		                           <div className="card-body">
		                              <div className="bdb mb-2">
		                                 <h2 className="card-title">Personal Information</h2>
		                              </div>
		                              <div className="table-responsive">
		                                 <table className="table table-nowrap table-baddresserless">
		                                    <tbody>
		                                       <tr>
		                                          <th scope="row">
		                                             <h5 className="m-0">Status :</h5>
		                                          </th>
		                                          <td>
		                                             <p className="m-0 font-size-13">
		                                                <select onchange="if (!window.__cfRLUnblockHandlers) return false; javascript:location.href = this.value;" data-cf-modified-50c95876690c09f445bfc885-="">		                                                
		                                                   {props.viewCompanyData && props.viewCompanyData.is_install==1?<>
				                                                   	<option value="1" selected>Active</option>
				                                                   <option value="0">Inactive</option>
		                                                   </>
		                                                   :<>
		                                                   		<option value="1">Active</option>
		                                                   		<option value="0" selected>Inactive</option>
		                                                   </>
		                                               }
		                                                   
		                                                </select>
		                                             </p>
		                                          </td>
		                                       </tr>
		                                       <tr>
		                                          <th scope="row">
		                                             <h5 className="m-0">Company Name:</h5>
		                                          </th>
		                                          <td>
		                                             <p className="m-0 font-size-13">{props.viewCompanyData && props.viewCompanyData.company_name}</p>
		                                          </td>
		                                       </tr>
		                                       <tr>
		                                          <th scope="row">
		                                             <h5 className="m-0">Company Contact Number:</h5>
		                                          </th>
		                                          <td>
		                                             <p className="m-0 font-size-13">{props.viewCompanyData && props.viewCompanyData.mobile}</p>
		                                          </td>
		                                       </tr>
		                                       <tr>
		                                          <th scope="row">
		                                             <h5 className="m-0">Company Contact Email:</h5>
		                                          </th>
		                                          <td>
		                                             <p className="m-0 font-size-13">{props.viewCompanyData && props.viewCompanyData.email}</p>
		                                          </td>
		                                       </tr>
		                                       <tr className="my-2">
		                                          <th scope="row">
		                                             <h5 className="m-0 font-size-14">Address:</h5>
		                                          </th>
		                                          <td>
		                                             <p className="m-0 font-size-13">{props.viewCompanyData && props.viewCompanyData.address}</p>
		                                          </td>
		                                       </tr>
		                                       <tr className="my-2">
		                                          <th scope="row">
		                                             <h5 className="m-0">Website URL:</h5>
		                                          </th>
		                                          <td>
		                                             <p className="m-0 font-size-13">{props.viewCompanyData && props.viewCompanyData.website_url}</p>
		                                          </td>
		                                       </tr>
		                                    </tbody>
		                                 </table>
		                              </div>
		                           </div>
		                        </div>
		                     </div>
		                  </div>
		                  <div className="col-md-4 col-lg-4 pr-0">
		                     <div className=" card aff_prof_box">
		                        <div className="">
		                           <div className="card-body">
		                              <div className="bdb mb-2">
		                                 <h2 className="card-title"> Information</h2>
		                              </div>
		                              <div className="table-responsive">
		                                 <table className="table table-nowrap mb-0 table-baddresserless">
		                                    <tbody>
		                                       <tr>
		                                          <th scope="row">
		                                             <h5 className="m-0">CIN No</h5>
		                                          </th>
		                                          <td>
		                                             <p className="m-0 font-size-13">{props.viewCompanyData && props.viewCompanyData.cin_no}</p>
		                                          </td>
		                                       </tr>
		                                       <tr>
		                                          <th scope="row">
		                                             <h5 className="m-0">GST NO:</h5>
		                                          </th>
		                                          <td>
		                                             <p className="m-0 font-size-13">{props.viewCompanyData && props.viewCompanyData.gst_no}</p>
		                                          </td>
		                                       </tr>
		                                       <tr>
		                                          <th scope="row">
		                                             <h5 className="m-0">PAN NO:</h5>
		                                          </th>
		                                          <td>
		                                             <p className="m-0 font-size-13">{props.viewCompanyData && props.viewCompanyData.pan_no}</p>
		                                          </td>
		                                       </tr>
		                                       <tr>
		                                          <th scope="row">
		                                             <h5 className="m-0">Company Category:</h5>
		                                          </th>
		                                          <td>
		                                             <p className="m-0 font-size-13">{props.viewCompanyData && props.viewCompanyData.company_category}</p>
		                                          </td>
		                                       </tr>
		                                       <tr className="my-2">
		                                          <th scope="row">
		                                             <h5 className="m-0 font-size-14">LandLine No:</h5>
		                                          </th>
		                                          <td>
		                                             <p className="m-0 font-size-13">{props.viewCompanyData && props.viewCompanyData.landline_no}</p>
		                                          </td>
		                                       </tr>
		                                     
		                                    </tbody>
		                                 </table>
		                              </div>
		                           </div>
		                        </div>
		                     </div>
		                  </div>
		               </div>
		            </div>
		         </div>
		      </section>
		      <section id="aff_tabs">
		         <div className="container">
		            <div className="tabs-wrapper">
		               <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
		                  <li className="nav-item" role="presentation">
		                     <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Plan History</button>
		                  </li>
		                  <li className="nav-item" role="presentation">
		                     <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Billing History</button>
		                  </li>
		                  <li className="nav-item" role="presentation">
		                     <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Ledger</button>
		                  </li>		                  
		               </ul>
		               <div className="tab-content card p-4" id="pills-tabContent">
		                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
		                     <div className="table-responsive">
		                        <table id="example" className="table table-striped" style={{width:'100%'}}>
		                           <thead>
		                              <tr>
		                                 <th>Plan</th>
		                                 <th>Start date</th>
		                                 <th>Expire date</th>
		                                 <th>Status</th>		                                 
		                              </tr>
		                           </thead>
		                           <tbody>
		                           {
		                           		props.viewCompanyData && props.viewCompanyData.company_plan_details.map((item)=>(
		                           			<>
		                           				 <tr>
					                                 <td>{item.plan_details.title}</td>
					                                 <td>{item.valid_from}</td>
					                                 <td>{item.valid_upto}</td>
					                                 <td>
					                                 {item.status==1?<>
					                                 	 <span className="badge badge-pill badge-soft-success font-size-11">
						                                      Active
						                                   </span>
					                                 </>:item.status==3?<>
					                                 	 <span className="badge badge-pill badge-soft-primary font-size-11">
					                                      Upcomming
					                                    </span>
					                                 </>:<>
					                                 	<span className="badge badge-pill badge-soft-danger font-size-11">
					                                      Expired
					                                    </span>
					                                 </>}
					                                 </td>
					                                 
					                              </tr>
		                           			</>
		                           		))
		                           }	                             
		                             
		                           </tbody>
		                        </table>
		                     </div>
		                  </div>
		                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
		                     <div className="table-responsive">
		                        <table id="example" className="table table-striped" style={{width:'100%'}}>
		                           <thead>
		                              <tr>
		                                 <th>Order ID</th>
		                                 <th>Transaction ID</th>		                                
		                                 <th>Amount</th>
		                                  <th>Date</th>
		                                 <th>Payment Mode</th>		                                
		                                 <th>Status</th>		                                 
		                              </tr>
		                           </thead>
		                           <tbody>
		                           {
		                           		props.viewCompanyData && props.viewCompanyData.plan_transaction.map((item)=>(
		                           			<>
		                           				 <tr>
					                                 <td>{item.order_id}</td>
					                                 <td>{item.transaction_id}</td>					                                
					                                 <td>{item.amount/100}</td>
					                                 <td>{item.created_at}</td>
					                                 <td>{item.payment_mode==1?'Cash':item.payment_mode==3?'Wallet':'Online'}</td>
					                                 <td>{item.status==2?<>
					                                 		<span className="badge badge-pill badge-soft-success font-size-11">
						                                      Success
						                                    </span>
					                                 </>:<>
					                                 <span className="badge badge-pill badge-soft-danger font-size-11">
					                                      Failed
					                                    </span>
					                                 </>}</td>
					                                 
					                              </tr>
		                           			</>
		                           			))
		                           }
		                             
		                             
		                           </tbody>
		                        </table>
		                     </div>
		                  </div>
		                  <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
		                     <div className="table-responsive">
		                        <table id="example" className="table table-striped" style={{width:'100%'}}>
		                           <thead>
		                              <tr>
		                              	 <th>Transaction Date</th>
		                                 <th>Order ID</th>
		                                 <th>Remark</th>
		                                 <th>Credit</th>
		                                 <th>Debit</th>
		                                 <th>Balance</th>		                                 
		                              </tr>
		                           </thead>
		                           <tbody>
		                           {
		                           		props.viewCompanyData && props.viewCompanyData.ledger.map((item)=>(
		                           			<>
		                           				 <tr>		                             		
					                                 <td>{item.created_at}</td>
					                                 <td>{item.order_id}</td>
					                                 <td>{item.remark}</td>
					                                 <td>{item.credit}</td>
					                                 <td>{item.debit}</td>
					                                 <td>{item.credit-item.debit}</td>
					                              </tr>
		                           			</>
		                           		))
		                           }
		                             
		                             
		                           </tbody>
		                        </table>
		                     </div>
		                  </div>
		                  
		               </div>
		            </div>
		         </div>
		      </section>
			</>
		)
}

export default CustomerProfile;