import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL, API_URL } from "../../config";
import api from "../api";
import axios from "axios";
import { toast } from "react-toastify";

const ManageLogoPage = () => {
  const [logoData, setLogoData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    loadlogo();
    setToken(localStorage.getItem("token"));
  }, []);

  const loadlogo = async () => {
    await axios
      .get(`${API_BASE_URL}/list-logo`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        setLogoData(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(logoData, "logoData");

  const handleDelete = (id) => {
    const result = window.confirm("Do you want to delete ?");

    if (result) {
      axios
        .delete(`${API_BASE_URL}/logo-delete/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === "200") {
            loadlogo();
            toast.success("Logo Deleted successfully");
          }
        });
    }
  };

  const handleEdit = (id) => {
    // api.delete(`/logo-delete/${id}`).then((response) => {
    //   if (response.data.status === "200") {
    //     loadlogo();
    //   }
    // });
  };
  const handleActive = async (status, id) => {
    let updatedStatus = status == 0 ? 1 : 0;

    const res = await axios.post(
      `${API_BASE_URL}/activate-logo`,
      { status: updatedStatus, id: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const response = JSON.parse(JSON.stringify(res));
    console.log(response);
    if (response.data.status === 200) {
      loadlogo();
      if (status == 1) {
        toast.success("Inactive successfully");
      } else {
        toast.success("Active successfully");
      }
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Manage Logo </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item active">Manage Logo</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-end">
              <div className="col-lg-12 mb-2 ">
                <Link
                  to={"/crm/managelogo/addlogo"}
                  data-repeater-create
                  type="button"
                  className="btn btn-success inner"
                  value="Add Number"
                >
                  Add a Logo
                </Link>
              </div>
            </div>

            <div className="col-xl-12">
              <div className="row">
                {logoData &&
                  logoData.map((item, index) => (
                    <>
                      <div className="col-md-4">
                        <div class="card p-3 h-auto">
                          {item.status == 1 && (
                            <span className="badge bg-success b-block w-25 position-absolute">
                              Active
                            </span>
                          )}

                          <img
                            class="card-img-top rounded mt-4"
                            src={API_URL + item.website_logo}
                            alt="Card image cap"
                            width="100"
                            height="200"
                          />

                          <div className="d-flex justify-content-center align-items-center mt-3">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm btn-rounded mt-2 me-2"
                              onClick={() => {
                                handleEdit(item.id);
                              }}
                            >
                              <i className="bx bx-edit"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm btn-rounded mt-2 me-2"
                              onClick={() => {
                                handleDelete(item.id);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-warning btn-sm btn-rounded mt-2 me-2"
                              onClick={() => {
                                handleActive(item.status, item.id);
                              }}
                            >
                              <i className="fa fa-ban"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageLogoPage;
