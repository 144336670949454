import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { API_BASE_URL, API_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";

const EditAdsBanner = () => {
  const [formData, setFormData] = useState({
    services: "",
    showin: "",
    title: "",
    shortorder: "",
    link: "",
    description: "",
  });
  const [show, setShow] = useState(true);
  const [pageError, setPageError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [shortOrderError, setShortOrderError] = useState("");
  const [linkError, setLinkError] = useState("");
  const [servicesError, setServicesError] = useState("");
  const [shownInError, setShownInError] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState();
  const [managePageData, setManagePageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("token"));

  const navigate = useNavigate();

  const { id } = useParams();
  const { description, showin, title, shortorder, link } = formData;
  let hasError = false;

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setToken(localStorage.getItem("token"));
      await axios
        .get(`${API_BASE_URL}/list-pages`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setManagePageData(response.data.result.reverse());
        })
        .catch((error) => {
          console.log(error);
        });

      const response = await axios.get(`${API_BASE_URL}/view-banner/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      });

      setFormData({
        ...formData,
        description: response.data.result.description,
        link: response.data.result.link,
        showin: response.data.result.page_id,
        shortorder: response.data.result.short_order,
        title: response.data.result.title,
      });
      setImagePreview(response.data.result.image);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formData.showin) {
      setShownInError("Please select shown in ");
      hasError = true;
    } else {
      setShownInError("");
    }
    if (!formData.title) {
      setTitleError("Please enter title");
      hasError = true;
    } else {
      setTitleError("");
    }
    if (!formData.shortorder) {
      hasError = true;
      setShortOrderError("Please enter short order");
    } else {
      setShortOrderError("");
    }
    if (!formData.link) {
      setLinkError("Please enter video link ");
      hasError = true;
    } else {
      setLinkError("");
    }

    if (!formData.description) {
      setDescriptionError("Please enter the description");
      hasError = true;
    } else {
      setDescriptionError("");
    }

    const form = new FormData();
    form.append("page_id", showin);
    form.append("title", title);
    form.append("short_order", shortorder);
    form.append("link", link);
    form.append("description", description);
    form.append("banner", image);
    form.append("id", id);

    if (!hasError) {
      axios
        .post(`${API_BASE_URL}/update-banner`, form, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.status === "200") {
            navigate("/crm/mangeadsbanner");
            toast.success("Update successfully");
          }
        })
        .catch((error) => {
          setPageError(error.response.data.result);
          console.log(error);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (name === "image") {
      setShow(false);
      setImage(event.target.files[0]);
    }
  };

  const handleImageChange = (e) => {
    setShow(false);
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Edit New Banner </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/crm/mangeadsbanner"}>
                          Manage Banner Page
                        </Link>
                      </li>

                      <li className="breadcrumb-item active">
                        Edit new Banner
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Edit Banner</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Shown In
                              </label>
                              <div className="col-lg-10">
                                <select
                                  className={
                                    shownInError
                                      ? " form-select is-invalid"
                                      : "form-control "
                                  }
                                  styles="max-width: 90px;"
                                  name="showin"
                                  onChange={handleInputChange}
                                  value={formData.showin}
                                >
                                  <option value="">Select an option</option>
                                  {managePageData?.map((item) => (
                                    <option value={item.id}>
                                      {item.page_name}
                                    </option>
                                  ))}
                                </select>
                                {shownInError && (
                                  <div className="text-danger">
                                    {shownInError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Title
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="title"
                                  type="text"
                                  value={formData.title}
                                  className={
                                    titleError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter title..."
                                  onChange={handleInputChange}
                                />
                                {titleError && (
                                  <div className="text-danger">
                                    {titleError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Short Order
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="shortorder"
                                  type="text"
                                  value={shortorder}
                                  className={
                                    shortOrderError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter short order..."
                                  onChange={handleInputChange}
                                />
                                {shortOrderError && (
                                  <div className="text-danger">
                                    {shortOrderError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Link
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="link"
                                  type="text"
                                  value={link}
                                  className={
                                    linkError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter link..."
                                  onChange={handleInputChange}
                                />
                                {linkError && (
                                  <div className="text-danger">{linkError}</div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Description
                              </label>
                              <div className="col-lg-10">
                                {description && (
                                  <Editor
                                    onEditorChange={
                                      handleEditorChangeDescription
                                    }
                                    init={{
                                      plugins:
                                        "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                      table_default_attributes: {
                                        border: "5",
                                      },
                                    }}
                                    initialValue={description}
                                  />
                                )}
                                {descriptionError && (
                                  <div className="text-danger">
                                    {descriptionError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="inner-repeater mb-4">
                              <div
                                data-repeater-list="inner-group"
                                className="inner form-group mb-0 row"
                              >
                                <label className="col-form-label col-lg-2">
                                  Image
                                </label>
                                <div
                                  data-repeater-item
                                  className="inner col-lg-10 ms-md-auto"
                                >
                                  <div className="mb-3 row align-items-center">
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-md-0">
                                        <input
                                          className={
                                            imageError
                                              ? "form-control is-invalid"
                                              : "form-control "
                                          }
                                          name="image"
                                          type="file"
                                          accept="image/*"
                                          onChange={(e) => {
                                            handleImageChange(e);
                                            handleInputChange(e);
                                          }}
                                        />
                                        {imageError && (
                                          <div className="text-danger">
                                            {imageError}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-20">
                                        <div>
                                          {imagePreview && (
                                            <>
                                              {show === true ? (
                                                <img
                                                  src={`${API_URL}${imagePreview}`}
                                                  alt="Preview"
                                                  width="200"
                                                  height="200"
                                                />
                                              ) : (
                                                <img
                                                  src={imagePreview}
                                                  alt="Preview"
                                                  width="200"
                                                  height="200"
                                                />
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}
                            <button type="submit" className="btn btn-primary">
                              Edit Content Page
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAdsBanner;
