import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";

const ManageSeoPage = () => {
  const [seoPage, setSeoPage] = useState();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [status, setStatus] = useState(0);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadContent();
  }, []);

  const loadContent = async () => {
    await axios
      .get(`${API_BASE_URL}/list-seo`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })

      .then((response) => {
        setSeoPage(response.data.result.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (id) => {
    const result = window.confirm("Do you want to delete ?");

    if (result) {
      axios
        .delete(`${API_BASE_URL}/seo-delete/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === "200") {
            toast.success("Delete successfully");
            axios

              .get(`${API_BASE_URL}//list-seo`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((response) => {
                setSeoPage(response.data.result.reverse());
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    }
  };

  const handleActive = async (status, id) => {
    let updatedStatus = status == 0 ? 1 : 0;

    const res = await axios.post(
      `${API_BASE_URL}/activate-seo-page`,
      { status: updatedStatus, id: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const response = JSON.parse(JSON.stringify(res));
    console.log(response);
    if (response.data.status === 200) {
      loadContent();
      if (status == 1) {
        toast.success("Active successfully");
      } else {
        toast.success("Inactive successfully");
      }
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Manage SEO Page </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Manage SEO Page
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-end">
              <div className="col-lg-12 mb-2 ">
                <Link
                  to={"/crm/manageseopage/addseopage"}
                  data-repeater-create
                  type="button"
                  className="btn btn-success inner"
                  value="Add Number"
                >
                  Add a new SEO page
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">
                      SEO Page List ({seoPage?.length})
                    </h4>
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th styles="width: 20px;">
                              <div className="form-check font-size-16 align-middle">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="transactionCheck01"
                                />
                                <label
                                  className="form-check-label"
                                  for="transactionCheck01"
                                ></label>
                              </div>
                            </th>
                            <th className="align-middle">Page id</th>
                            <th className="align-middle">Page Name</th>
                            <th className="align-middle">Page URL</th>
                            <th className="align-middle">Meta Title</th>
                            {/* <th className="align-middle">Meta keywords</th> */}
                            {/* <th className="align-middle">Description</th> */}
                            <th className="align-middle">Status</th>
                            <th className="align-middle">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {seoPage?.map((item) => (
                            <tr>
                              <td>
                                <div className="form-check font-size-16">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="transactionCheck02"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="transactionCheck02"
                                  ></label>
                                </div>
                              </td>
                              <td>
                                <a
                                  href="javascript: void(0);"
                                  className="text-body fw-bold"
                                >
                                  {item.id}
                                </a>
                              </td>
                              <td>{item.page_name}</td>
                              <td>{item.page_url}</td>
                              <td>{item.meta_title}</td>

                              {/* <td> */}
                              {/* {item.meta_keywords} */}
                              {/* <span className="badge badge-pill badge-soft-success font-size-11">
                              
                              </span> */}
                              {/* </td> */}

                              <td>
                                {item.status == 1 ? (
                                  <span className="badge badge-pill badge-soft-success font-size-11">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge badge-pill badge-soft-danger font-size-11">
                                    Inactive
                                  </span>
                                )}
                              </td>

                              <td>
                                <Link
                                  to={`/crm/manageseopage/editseopage/${item.id}`}
                                  type="button"
                                  className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                                >
                                  <i className="fa fa-pen"></i>
                                </Link>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm btn-rounded waves-effect waves-light ms-2"
                                  onClick={() => {
                                    handleDelete(item.id);
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-warning btn-sm btn-rounded  ms-2"
                                  onClick={() => {
                                    handleActive(item.status, item.id);
                                  }}
                                >
                                  <i className="fa fa-ban"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageSeoPage;
