import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";

const CompanyInformation = () => {
  const [formData, setFormData] = useState({
    email: "",
    mobile: "",
    address: "",
    email_to: "",
    reply_to: "",
    copy_right: "",
    app_store_link: "",
    play_store_link: "",
  });

  const {
    email,
    mobile,
    address,
    email_to,
    reply_to,
    copy_right,
    app_store_link,
    play_store_link,
  } = formData;

  const [pageError, setPageError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [shortOrderError, setShortOrderError] = useState("");
  const [linkError, setLinkError] = useState("");
  const [servicesError, setServicesError] = useState("");
  const [shownInError, setShownInError] = useState("");

  const [addressError, setAddressError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailToError, setEmailToError] = useState("");
  const [replyToError, setReplyToError] = useState("");
  const [copyrightError, setCopyrightError] = useState("");
  const [playStoreError, setPlayStoreError] = useState("");
  const [appStoreError, setAppStoreError] = useState("");

  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState();
  const [managePageData, setManagePageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("token"));

  const navigate = useNavigate();

  const { id } = useParams();

  let hasError = false;

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if (id) {
      ViewCompanyProfile();
    }
  }, []);

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const ViewCompanyProfile = async () => {
    const res = await axios.get(`${API_BASE_URL}/view-company-profile/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res, "res");
    if (res.data.status == 200) {
      setFormData({
        ...formData,
        email: res.data.result.email,
        mobile: res.data.result.mobile,
        address: res.data.result.address,
        email_to: res.data.result.email_to,
        reply_to: res.data.result.reply_to,
        copy_right: res.data.result.copy_right,
        app_store_link: res.data.result.app_store_link,
        play_store_link: res.data.result.play_store_link,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData, "form");

    if (!formData.email) {
      setEmailError("Please Enter Email ");
      hasError = true;
    } else {
      setEmailError("");
    }
    if (!formData.mobile) {
      setMobileError("Please enter mobile no.");
      hasError = true;
    } else {
      setMobileError("");
    }
    if (!formData.address) {
      hasError = true;
      setAddressError("Please enter address");
    } else {
      setAddressError("");
    }
    if (!formData.email_to) {
      setEmailToError("Please enter  ");
      hasError = true;
    } else {
      setEmailToError("");
    }

    if (!formData.reply_to) {
      setReplyToError("Please enter");
      hasError = true;
    } else {
      setReplyToError("");
    }

    if (!formData.play_store_link) {
      setPlayStoreError("Please enter play store link");
      hasError = true;
    } else {
      setPlayStoreError("");
    }

    if (!formData.app_store_link) {
      setAppStoreError("Please enter app store link  ");
      hasError = true;
    } else {
      setAppStoreError("");
    }

    if (!formData.reply_to) {
      setReplyToError("Please enter");
      hasError = true;
    } else {
      setReplyToError("");
    }

    if (!hasError) {
      axios
        .post(`${API_BASE_URL}/add-company-profile`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status === 200) {
            navigate("/crm/managecompanyprofile");
            toast.success("Added successfully");
          }
        })
        .catch((error) => {
          setPageError(error.response.data.result);
          console.log(error);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Company Information </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/crm/managecompanyprofile"}>
                          Company Information
                        </Link>
                      </li>

                      <li className="breadcrumb-item active">
                        Add Company Information
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">
                        Add Company Information
                      </h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Company Name
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="company_name"
                                  name="company_name"
                                  type="text"
                                  value={email}
                                  className={
                                    emailError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Company Name"
                                  onChange={handleInputChange}
                                />
                                {emailError && (
                                  <div className="text-danger">
                                    {emailError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Website
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="website"
                                  name="website"
                                  type="text"
                                  value={email}
                                  className={
                                    emailError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Website Name"
                                  onChange={handleInputChange}
                                />
                                {emailError && (
                                  <div className="text-danger">
                                    {emailError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Mobile
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="mobile"
                                  name="mobile"
                                  type="text"
                                  value={email}
                                  className={
                                    emailError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Mobile"
                                  onChange={handleInputChange}
                                />
                                {emailError && (
                                  <div className="text-danger">
                                    {emailError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Phone Number
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="phone_number"
                                  name="phone_number"
                                  type="text"
                                  value={email}
                                  className={
                                    emailError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Phone Number"
                                  onChange={handleInputChange}
                                />
                                {emailError && (
                                  <div className="text-danger">
                                    {emailError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Alternate Number
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="alternate_number"
                                  name="alternate_number"
                                  type="text"
                                  value={email}
                                  className={
                                    emailError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Alternate Number"
                                  onChange={handleInputChange}
                                />
                                {emailError && (
                                  <div className="text-danger">
                                    {emailError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Toll Free No.
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="toll_free_no"
                                  name="toll_free_no"
                                  type="text"
                                  value={email}
                                  className={
                                    emailError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Toll Free No."
                                  onChange={handleInputChange}
                                />
                                {emailError && (
                                  <div className="text-danger">
                                    {emailError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Email Address
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="email_address"
                                  name="email_address"
                                  type="text"
                                  value={email}
                                  className={
                                    emailError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Email Address"
                                  onChange={handleInputChange}
                                />
                                {emailError && (
                                  <div className="text-danger">
                                    {emailError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Alternate E-Mail
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="alternate_email"
                                  name="alternate_email"
                                  type="text"
                                  value={mobile}
                                  className={
                                    mobileError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Alternate Email"
                                  onChange={handleInputChange}
                                />
                                {mobileError && (
                                  <div className="text-danger">
                                    {mobileError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                India Address
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="india_address"
                                  name="india_address"
                                  type="text"
                                  value={address}
                                  className={
                                    addressError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter India Address"
                                  onChange={handleInputChange}
                                />
                                {addressError && (
                                  <div className="text-danger">
                                    {addressError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                U.S Address
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="us_address"
                                  name="us_address"
                                  type="text"
                                  value={email_to}
                                  className={
                                    emailToError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter U.S Address"
                                  onChange={handleInputChange}
                                />
                                {emailToError && (
                                  <div className="text-danger">
                                    {emailToError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Logo Tittle
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="logo_tittle"
                                  name="logo_tittle"
                                  type="text"
                                  value={reply_to}
                                  className={
                                    replyToError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Logo Tittle"
                                  onChange={handleInputChange}
                                />
                                {replyToError && (
                                  <div className="text-danger">
                                    {replyToError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Facebook Link
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="facebook_link"
                                  name="copy_right"
                                  type="text"
                                  value={copy_right}
                                  className={
                                    copyrightError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Facebook Link"
                                  onChange={handleInputChange}
                                />
                                {copyrightError && (
                                  <div className="text-danger">
                                    {copyrightError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Twitter Link
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="twitter_link"
                                  name="twitter_link"
                                  type="text"
                                  value={copy_right}
                                  className={
                                    copyrightError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Twitter Link"
                                  onChange={handleInputChange}
                                />
                                {copyrightError && (
                                  <div className="text-danger">
                                    {copyrightError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Linked In Link
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="linkedin_link"
                                  name="linkedin_link"
                                  type="text"
                                  value={copy_right}
                                  className={
                                    copyrightError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter LinkedIn Link"
                                  onChange={handleInputChange}
                                />
                                {copyrightError && (
                                  <div className="text-danger">
                                    {copyrightError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                G+ Link
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="g_link"
                                  name="g_link"
                                  type="text"
                                  value={copy_right}
                                  className={
                                    copyrightError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter G+ Link"
                                  onChange={handleInputChange}
                                />
                                {copyrightError && (
                                  <div className="text-danger">
                                    {copyrightError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Service Tax No.
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="service_tax_no"
                                  name="service_tax_no"
                                  type="text"
                                  value={copy_right}
                                  className={
                                    copyrightError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Service Tax No."
                                  onChange={handleInputChange}
                                />
                                {copyrightError && (
                                  <div className="text-danger">
                                    {copyrightError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                CIN No.
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="app_store_link"
                                  name="app_store_link"
                                  type="text"
                                  value={app_store_link}
                                  className={
                                    appStoreError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter CIN No."
                                  onChange={handleInputChange}
                                />
                                {appStoreError && (
                                  <div className="text-danger">
                                    {appStoreError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                GST No.
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="linkedin_link"
                                  name="linkedin_link"
                                  type="text"
                                  value={copy_right}
                                  className={
                                    copyrightError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter GST No."
                                  onChange={handleInputChange}
                                />
                                {copyrightError && (
                                  <div className="text-danger">
                                    {copyrightError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                SAC No.
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="g_link"
                                  name="g_link"
                                  type="text"
                                  value={copy_right}
                                  className={
                                    copyrightError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter SAC No."
                                  onChange={handleInputChange}
                                />
                                {copyrightError && (
                                  <div className="text-danger">
                                    {copyrightError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                A/C Detail
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="service_tax_no"
                                  name="service_tax_no"
                                  type="text"
                                  value={copy_right}
                                  className={
                                    copyrightError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter A/C Detial"
                                  onChange={handleInputChange}
                                />
                                {copyrightError && (
                                  <div className="text-danger">
                                    {copyrightError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Service SMS Send
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="app_store_link"
                                  name="app_store_link"
                                  type="checkbox"
                                  value={app_store_link}
                                  className={
                                    appStoreError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  onChange={handleInputChange}
                                />
                                {appStoreError && (
                                  <div className="text-danger">
                                    {appStoreError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Service E-Mail Send
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="app_store_link"
                                  name="app_store_link"
                                  type="checkbox"
                                  value={app_store_link}
                                  className={
                                    appStoreError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  onChange={handleInputChange}
                                />
                                {appStoreError && (
                                  <div className="text-danger">
                                    {appStoreError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                SMTP E-Mail ID
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="g_link"
                                  name="g_link"
                                  type="text"
                                  value={copy_right}
                                  className={
                                    copyrightError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Copyright"
                                  onChange={handleInputChange}
                                />
                                {copyrightError && (
                                  <div className="text-danger">
                                    {copyrightError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                SMTP Password
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="service_tax_no"
                                  name="service_tax_no"
                                  type="text"
                                  value={copy_right}
                                  className={
                                    copyrightError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter SMTP Password"
                                  onChange={handleInputChange}
                                />
                                {copyrightError && (
                                  <div className="text-danger">
                                    {copyrightError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Logo
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="app_store_link"
                                  name="app_store_link"
                                  type="file"
                                  value={app_store_link}
                                  className={
                                    appStoreError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter app store link"
                                  onChange={handleInputChange}
                                />
                                {appStoreError && (
                                  <div className="text-danger">
                                    {appStoreError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Signature
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="linkedin_link"
                                  name="linkedin_link"
                                  type="file"
                                  value={copy_right}
                                  className={
                                    copyrightError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Copyright"
                                  onChange={handleInputChange}
                                />
                                {copyrightError && (
                                  <div className="text-danger">
                                    {copyrightError}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}
                            <button type="submit" className="btn btn-primary">
                              Add Company
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyInformation;
