import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";

const EditContentPage = () => {
  const [content, setContent] = useState();
  const [formData, setFormData] = useState({
    description: "",
    parentlink: "",
    image1: "",
  });
  const [image, setImage] = useState();
  const navigate = useNavigate();
  const [parentLinkError, setParentLinkError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [managePageData, setManagePageData] = useState();
  const [pageError, setPageError] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));

  let hasError = false;

  const { id } = useParams();

  const { description, parentlink, image1 } = formData;
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setToken(localStorage.getItem("token"));

      await axios
        .get(`${API_BASE_URL}/list-pages`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          setManagePageData(response.data.result.reverse());
        })
        .catch((error) => {
          console.log(error);
        });

      const response = await axios.get(
        `${API_BASE_URL}/view-content-page/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
          },
        }
      );

      setContent(response.data.result);

      setFormData({
        ...formData,
        description: response.data.result.page_content,
        parentlink: response.data.result.page_id,
        image1: response.data.result.image,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (!formData.parentlink) {
      setParentLinkError("Please select parentlink");
      hasError = true;
    } else {
      setParentLinkError("");
    }
    if (!formData.description) {
      setDescriptionError("Please enter the description");
      hasError = true;
    } else {
      setDescriptionError("");
    }

    // const rawContent = {
    //   page_id: parentlink,
    //   page_content: description,
    //   id: id,
    // };

    let data = JSON.stringify({
      page_id: parentlink,
      page_content: description,
      id: id,
    });

    if (!hasError) {
      axios
        .post(`${API_BASE_URL}/update-page-content`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status === "200") {
            navigate("/crm/managecontentpage");
            toast.success("Added successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          setPageError(error.response.data.result);
        });
    }

    console.log(formData, "form");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (name === "image") {
      setImage(event.target.files[0]);
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Edit Content Page </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/crm/managecontentpage"}>
                          Manage Content Page
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Edit Content Page
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Edit Content Page</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Parent Link
                              </label>
                              <div className="col-lg-10">
                                <select
                                  className={
                                    parentLinkError
                                      ? " form-select is-invalid"
                                      : "form-control "
                                  }
                                  styles="max-width: 90px;"
                                  name="parentlink"
                                  value={formData.parentlink}
                                  onChange={handleInputChange}
                                >
                                  <option value="">Select an option</option>
                                  {managePageData?.map((item) => (
                                    <option value={item.id}>
                                      {item.page_name}
                                    </option>
                                  ))}
                                </select>
                                {parentLinkError && (
                                  <div className="text-danger">
                                    {parentLinkError}
                                  </div>
                                )}
                              </div>
                            </div>
                            {description && (
                              <div className="form-group row mb-4">
                                <label className="col-form-label col-lg-2">
                                  Description
                                </label>
                                <div className="col-lg-10">
                                  <Editor
                                    onEditorChange={
                                      handleEditorChangeDescription
                                    }
                                    init={{
                                      plugins:
                                        "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                      table_default_attributes: {
                                        border: "5",
                                      },
                                    }}
                                    initialValue={description}
                                  />
                                  {descriptionError && (
                                    <div className="text-danger">
                                      {descriptionError}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            <div className="inner-repeater mb-4">
                              <div
                                data-repeater-list="inner-group"
                                className="inner form-group mb-0 row"
                              >
                                <label className="col-form-label col-lg-2">
                                  Image
                                </label>
                                <div
                                  data-repeater-item
                                  className="inner col-lg-10 ms-md-auto"
                                >
                                  <div className="mb-3 row align-items-center">
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-md-0">
                                        <input
                                          className={
                                            imageError
                                              ? "form-control is-invalid"
                                              : "form-control "
                                          }
                                          name="image"
                                          type="file"
                                          accept="image/*"
                                          onChange={(e) => {
                                            handleImageChange(e);
                                            handleInputChange(e);
                                          }}
                                        />
                                        {imageError && (
                                          <div className="text-danger">
                                            {imageError}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-20">
                                        <div>
                                          {imagePreview && (
                                            <img
                                              src={imagePreview}
                                              alt="Preview"
                                              width="200"
                                              height="200"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}

                            <button type="submit" className="btn btn-primary">
                              Edit Content Page
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditContentPage;
