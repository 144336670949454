import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { toast } from "react-toastify";

const AddIndustry = () => {
  const [formData, setFormData] = useState({
    name: "",
  });
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [pageError, setPageError] = useState("");
  const [IndustryError, setQuestionError] = useState("");
  const navigate = useNavigate();
  let hasError = false;

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formData.name) {
      setQuestionError("Please  enter name ");
      hasError = true;
    } else {
      setQuestionError("");
    }

    if (!hasError) {
      axios
        .post(
          `${API_BASE_URL}/add-industry`,
          {
            name: formData.name,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status === 200) {
            navigate("/crm/manageindustry");
            toast.success("Addded successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          setPageError(error.response.data.result);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Add Industry </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/crm/manageindustry"}>Manage Industry</Link>
                      </li>
                      <li className="breadcrumb-item active">Add Industry</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Add Industry</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Industry Name
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="name"
                                  type="text"
                                  className={
                                    IndustryError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter page name ..."
                                  onChange={handleInputChange}
                                />
                                {IndustryError && (
                                  <div className="text-danger">
                                    {IndustryError}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}
                            <button type="submit" className="btn btn-primary">
                              Add Industry
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddIndustry;
