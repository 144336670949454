import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";

const AddNewTestimonials = () => {
  const [formData, setFormData] = useState({
    pageurl: "",
    pagename: "",
    rating: "",
    metakeywords: "",
    description: "",
    cityandcountry: "",
  });
  const [pageError, setPageError] = useState("");
  const [image, setImage] = useState("");
  const [pageurlError, setPageurlError] = useState("");
  const [pageNameError, setPageNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [ratingError, setRatingError] = useState("");
  const [cityAndCountryError, setCityAndCountry] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [managePageData, setManagePageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("token"));

  const navigate = useNavigate();
  let hasError = false;

  useEffect(() => {
    setToken(localStorage.getItem("token"));

    axios
      .get(`${API_BASE_URL}/list-pages`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setManagePageData(response.data.result.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.pageurl) {
      setPageurlError("Please enter page url");
      hasError = true;
    } else {
      setPageurlError("");
    }
    if (!formData.pagename) {
      setPageNameError("Please enter pagename");
      hasError = true;
    } else {
      setPageNameError("");
    }
    if (!formData.rating) {
      setRatingError("Please enter rating ");
      hasError = true;
    } else {
      setRatingError("");
    }

    if (!formData.description) {
      setDescriptionError("Please enter the description");
      hasError = true;
    } else {
      setDescriptionError("");
    }
    if (!formData.cityandcountry) {
      setCityAndCountry("Please enter the city");
      hasError = true;
    } else {
      setCityAndCountry("");
    }
    if (!formData.image) {
      setImageError("Please select the image");
      hasError = true;
    } else {
      setImageError("");
    }

    console.log(image, "image");
    const form = new FormData();
    form.append("name", formData.pagename);
    form.append("city_country", formData.cityandcountry);
    form.append("url", formData.pageurl);
    form.append("rating", formData.rating);
    form.append("description", formData.description);
    form.append("testimonial", image);

    if (!hasError) {
      axios
        .post(`${API_BASE_URL}/create-testimonial`, form, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status === "200") {
            navigate("/crm/managetestimonials");
            toast.success("Delete successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          setPageError(error.response.data.result);
        });
    }
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (name === "image") {
      setImage(event.target.files[0]);
    }
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">
                    Add Testimonials Page{" "}
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/crm/managetestimonials"}>
                          Manage Testimoials Page
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Add Testimonials Page
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Add Testimonials Page</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Page Name
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="pagename"
                                  type="text"
                                  className={
                                    pageNameError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter pagename..."
                                  onChange={handleInputChange}
                                />
                                {pageNameError && (
                                  <div className="text-danger">
                                    {pageNameError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                City and country
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="cityandcountry"
                                  type="text"
                                  className={
                                    cityAndCountryError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter city and country url..."
                                  onChange={handleInputChange}
                                />
                                {cityAndCountryError && (
                                  <div className="text-danger">
                                    {cityAndCountryError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Page url
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="pageurl"
                                  type="text"
                                  className={
                                    pageurlError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter page url..."
                                  onChange={handleInputChange}
                                />
                                {pageurlError && (
                                  <div className="text-danger">
                                    {pageurlError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Ratings
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="rating"
                                  type="text"
                                  className={
                                    ratingError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter rating..."
                                  onChange={handleInputChange}
                                />
                                {ratingError && (
                                  <div className="text-danger">
                                    {ratingError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Description
                              </label>
                              <div className="col-lg-10">
                                <Editor
                                  onEditorChange={handleEditorChangeDescription}
                                  init={{
                                    plugins:
                                      "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    table_default_attributes: {
                                      border: "5",
                                    },
                                  }}
                                />

                                {descriptionError && (
                                  <div className="text-danger">
                                    {descriptionError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="inner-repeater mb-4">
                              <div
                                data-repeater-list="inner-group"
                                className="inner form-group mb-0 row"
                              >
                                <label className="col-form-label col-lg-2">
                                  Image
                                </label>
                                <div
                                  data-repeater-item
                                  className="inner col-lg-10 ms-md-auto"
                                >
                                  <div className="mb-3 row align-items-center">
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-md-0">
                                        <input
                                          className={
                                            imageError
                                              ? "form-control is-invalid"
                                              : "form-control "
                                          }
                                          name="image"
                                          type="file"
                                          accept="image/*"
                                          onChange={(e) => {
                                            handleImageChange(e);
                                            handleInputChange(e);
                                          }}
                                        />
                                        {imageError && (
                                          <div className="text-danger">
                                            {imageError}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-20">
                                        <div>
                                          {imagePreview && (
                                            <img
                                              src={imagePreview}
                                              alt="Preview"
                                              width="200"
                                              height="200"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}
                            <button type="submit" className="btn btn-primary">
                              Add Content Page
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewTestimonials;
