import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";

const EditSlider = () => {
  const [formData, setFormData] = useState({
    title: "",
    shortorder: "",
    videolink: "",
    description: "",
    image: "",
  });
  const [titleError, setTitleError] = useState("");
  const [shortOrderError, setShortOrderError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [imagePreview, setImagePreview] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData, "form");

    if (!formData.title) {
      setTitleError("Please enter title");
    } else {
      setTitleError("");
    }
    if (!formData.shortorder) {
      setShortOrderError("Please enter short order");
    } else {
      setShortOrderError("");
    }
    if (!formData.videolink) {
      setVideoLink("Please enter video link ");
    } else {
      setVideoLink("");
    }

    if (!formData.description) {
      setDescriptionError("Please enter the description");
    } else {
      setDescriptionError("");
    }

    if (!formData.image) {
      setImageError("Please select the image");
    } else {
      setImageError("");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Edit Slider </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/crm/manageslider"}>
                          {" "}
                          Manage Slider Page
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">Edit Slider </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Edit Slider</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Title
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="title"
                                  type="text"
                                  className={
                                    titleError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter title..."
                                  onChange={handleInputChange}
                                />
                                {titleError && (
                                  <div className="text-danger">
                                    {titleError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Short Order
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="shortorder"
                                  type="text"
                                  className={
                                    shortOrderError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter short order..."
                                  onChange={handleInputChange}
                                />
                                {shortOrderError && (
                                  <div className="text-danger">
                                    {shortOrderError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Video Link
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="videolink"
                                  type="text"
                                  className={
                                    videoLink
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter short order..."
                                  onChange={handleInputChange}
                                />
                                {videoLink && (
                                  <div className="text-danger">{videoLink}</div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Description
                              </label>
                              <div className="col-lg-10">
                                <Editor
                                  onEditorChange={handleEditorChangeDescription}
                                  init={{
                                    plugins: "table",
                                    table_default_attributes: {
                                      border: "5",
                                    },
                                  }}
                                />
                                {descriptionError && (
                                  <div className="text-danger">
                                    {descriptionError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="inner-repeater mb-4">
                              <div
                                data-repeater-list="inner-group"
                                className="inner form-group mb-0 row"
                              >
                                <label className="col-form-label col-lg-2">
                                  Image
                                </label>
                                <div
                                  data-repeater-item
                                  className="inner col-lg-10 ms-md-auto"
                                >
                                  <div className="mb-3 row align-items-center">
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-md-0">
                                        <input
                                          className={
                                            imageError
                                              ? "form-control is-invalid"
                                              : "form-control "
                                          }
                                          name="image"
                                          type="file"
                                          accept="image/*"
                                          onChange={(e) => {
                                            handleImageChange(e);
                                            handleInputChange(e);
                                          }}
                                        />
                                        {imageError && (
                                          <div className="text-danger">
                                            {imageError}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-20">
                                        <div>
                                          {imagePreview && (
                                            <img
                                              src={imagePreview}
                                              alt="Preview"
                                              width="200"
                                              height="200"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            <button type="submit" className="btn btn-primary">
                              Edit Content Page
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSlider;
