import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../api";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";

const ManageIndustry = () => {
  const [faqData, setFaqData] = useState();
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadContent();
  }, []);

  const loadContent = async () => {
    axios
      .get(
        `${API_BASE_URL}/list-industry`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setFaqData(response.data.result.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (id) => {
    const result = window.confirm("Do you want to delete ?");

    if (result) {
      axios
        .get(`${API_BASE_URL}/industry-delete/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === "200") {
            toast.success("Delete successully");
            loadContent();
          }
        });
    }
  };

  const handleActive = async (status, id) => {
    let updatedStatus = status == 0 ? 1 : 0;

    const res = await axios.post(
      `${API_BASE_URL}/activate-industry`,
      { status: updatedStatus, id: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const response = JSON.parse(JSON.stringify(res));
    console.log(response);
    if (response.data.status === 200) {
      loadContent();
      if (status == 1) {
        toast.success("Inactive successfully");
      } else {
        toast.success("Active successfully");
      }
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Manage Industry </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Manage Industry
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-end">
              <div className="col-lg-12 mb-2 ">
                <Link
                  to={"/crm/manageindustry/addindustry"}
                  data-repeater-create
                  type="button"
                  className="btn btn-success inner"
                  value="Add Number"
                >
                  Add Industry
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">
                      FAQ List ({faqData?.length})
                    </h4>
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th styles="width: 20px;">
                              <div className="form-check font-size-16 align-middle">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="transactionCheck01"
                                />
                                <label
                                  className="form-check-label"
                                  for="transactionCheck01"
                                ></label>
                              </div>
                            </th>
                            <th className="align-middle">Name</th>
                            <th className="align-middle">Status</th>
                            <th className="align-middle">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {faqData?.map((item) => (
                            <tr>
                              <td>
                                <div className="form-check font-size-16">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="transactionCheck02"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="transactionCheck02"
                                  ></label>
                                </div>
                              </td>
                              {/* <td>
                                <a
                                  href="javascript: void(0);"
                                  className="text-body fw-bold"
                                >
                                  {item.id}
                                </a>
                              </td> */}

                              <td>{item.name}</td>

                              <td>
                                {item.status == 1 ? (
                                  <span className="badge badge-pill badge-soft-success font-size-11">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge badge-pill badge-soft-danger font-size-11">
                                    Inactive
                                  </span>
                                )}
                              </td>

                              <td>
                                <Link
                                  to={`/crm/manageindustry/editIndustry/${item.id}`}
                                  type="button"
                                  className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                                >
                                  <i className="fa fa-pen"></i>
                                </Link>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm btn-rounded waves-effect waves-light ms-2"
                                  onClick={() => {
                                    handleDelete(item.id);
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-warning btn-sm btn-rounded  ms-2"
                                  onClick={() => {
                                    handleActive(item.status, item.id);
                                  }}
                                >
                                  <i className="fa fa-ban"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageIndustry;
