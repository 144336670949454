import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const LeftSideBar = () => {
  const [dropdown, setDropDown] = useState(false);
  const [dropdown2, setDropDown2] = useState(false);
  const [dropdown3, setDropDown3] = useState(false);
  const [active, setActive] = useState("");

  const handleDropdown = () => {
    setDropDown((prevState) => !prevState);
    setDropDown2(false);
    setDropDown3(false);
  };

  const handleManageEnquiry = () => {
    setDropDown2((prevState) => !prevState);
    setDropDown(false);
    setDropDown3(false);
  };

  const handleManageCustomer = () => {
    setDropDown3((prevState) => !prevState);
    setDropDown(false);
    setDropDown2(false);
  };

  return (
    <div className="vertical-menu">
      <div data-simplebar className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <li className={active == "/crm/home" ? "active" : ""}>
                <Link to={"/crm/home"} className="waves-effect" type="button">
                  <i className="bx bx-layout"></i>
                  <span key="t-dashboards">Dashboard</span>
                </Link>
              </li>

              <a
                href="javascript: void(0);"
                className="has-arrow waves-effect"
                type="button"
                onClick={() => {
                  handleDropdown();
                }}
              >
                <i className="bx bx-home-circle"></i>
                <span key="t-dashboards">CMS</span>
              </a>
              {dropdown && (
                <ul className="sub-menu" aria-expanded="false">
                  {/* <li
                    className={
                      active == "/crm/companyinformation" ||
                      active == "/crm/companyinformation/addsocials"
                        ? "active"
                        : ""
                    }
                  >
                    <Link
                      to={"/crm/companyinformation"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("companyinformation");
                      }}
                    >
                      Company Informantion
                    </Link>
                  </li> */}
                  <li className={active == "managelogo" ? "active" : ""}>
                    <Link
                      to={"/crm/managelogo"}
                      key="t-default"
                      type="button"
                      onClick={() => {
                        setActive("managelogo");
                      }}
                    >
                      Manage Logo
                    </Link>
                  </li>
                  <li className={active === "managepage" ? "active" : ""}>
                    <Link
                      to={"/crm/managepage"}
                      key="t-default"
                      type="button"
                      onClick={() => {
                        setActive("managepage");
                      }}
                    >
                      Manage Pages
                    </Link>
                  </li>
                  <li className={active == "managecontentpage" ? "active" : ""}>
                    <Link
                      to={"/crm/managecontentpage"}
                      key="t-default"
                      type="button"
                      onClick={() => {
                        setActive("managecontentpage");
                      }}
                    >
                      Manage Content Page
                    </Link>
                  </li>
                  <li className={active == "manageslider" ? "active" : ""}>
                    <Link
                      to={"/crm/manageslider"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("manageslider");
                      }}
                    >
                      Manage Slider
                    </Link>
                  </li>
                  <li className={active == "manageseopage" ? "active" : ""}>
                    <Link
                      to={"/crm/manageseopage"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("manageseopage");
                      }}
                    >
                      Manage SEO page
                    </Link>
                  </li>
                  <li
                    className={active == "managetestimonials" ? "active" : ""}
                  >
                    <Link
                      to={"/crm/managetestimonials"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("managetestimonials");
                      }}
                    >
                      Manage Testimonials
                    </Link>
                  </li>
                  <li className={active == "mangeadsbanner" ? "active" : ""}>
                    <Link
                      to={"/crm/mangeadsbanner"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("mangeadsbanner");
                      }}
                    >
                      Manage Ads Banner
                    </Link>
                  </li>
                  <li className={active == "managefaq" ? "active" : ""}>
                    <Link
                      to={"/crm/managefaq"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("managefaq");
                      }}
                    >
                      Manage Faq
                    </Link>
                  </li>
                  <li className={active == "managesocialmedia" ? "active" : ""}>
                    <Link
                      to={"/crm/managesocialmedia"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("managesocialmedia");
                      }}
                    >
                      Manage Social Media
                    </Link>
                  </li>
                  <li className={active == "manageindustry" ? "active" : ""}>
                    <Link
                      to={"/crm/manageindustry"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("manageindustry");
                      }}
                    >
                      Manage Industry
                    </Link>
                  </li>
                  <li className={active == "managesubscribe" ? "active" : ""}>
                    <Link
                      to={"/crm/managesubscribe"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("managesubscribe");
                      }}
                    >
                      Manage Subscribe
                    </Link>
                  </li>
                  <li
                    className={active == "managecompanyprofile" ? "active" : ""}
                  >
                    <Link
                      to={"/crm/managecompanyprofile"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("managecompanyprofile");
                      }}
                    >
                      Manage Company Profile
                    </Link>
                  </li>
                  <li className={active == "manageclient" ? "active" : ""}>
                    <Link
                      to={"/crm/manageclient"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("manageclient");
                      }}
                    >
                      Manage Client
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <a
                href="javascript: void(0);"
                className="has-arrow waves-effect"
                type="button"
                onClick={() => {
                  handleManageEnquiry();
                }}
              >
                <i className="bx bx-layout"></i>

                <span key="t-dashboards">Manage Enquiry</span>
              </a>

              {dropdown2 && (
                <ul className="sub-menu" aria-expanded="false">
                  <li className={active == "manageenquiry" ? "active" : ""}>
                    <Link
                      to={"/crm/manageenquiry"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("manageenquiry");
                      }}
                    >
                      Manage Customer Enquiry
                    </Link>
                  </li>

                  <li className={active == "positive-enquiry" ? "active" : ""}>
                    <Link
                      to={"/crm/manageenquiry/positive-enquiry"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("positive-enquiry");
                      }}
                    >
                      Positive Enquiry
                    </Link>
                  </li>

                  <li className={active == "negative-enquiry" ? "active" : ""}>
                    <Link
                      to={"/crm/manageenquiry/negative-enquiry"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("negative-enquiry");
                      }}
                    >
                      Negative Enquiry
                    </Link>
                  </li>
                </ul>
              )}
            </li>

             <li>
              <a
                href="javascript: void(0);"
                className="has-arrow waves-effect"
                type="button"
                onClick={() => {
                  handleManageCustomer();
                }}
              >
                <i className="bx bx-layout"></i>

                <span key="t-dashboards">Manage Customer</span>
              </a>

              {dropdown3 && (
                <ul className="sub-menu" aria-expanded="false">
                  <li className={active == "manageenquiry" ? "active" : ""}>
                    <Link
                      to={"/crm/manage-customer"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("manageenquiry");
                      }}
                    >
                      Manage Customer
                    </Link>
                  </li>                
                </ul>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LeftSideBar;
