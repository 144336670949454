import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";

const AddEnquiry = () => {
  const [formData, setFormData] = useState({
    name: "",
    email_id: "",
    phone_no: "",
    state: "",
    subject: "",
    lead_source: "",
    language: "",
  });
  const [pageError, setPageError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [shortOrderError, setShortOrderError] = useState("");
  const [linkError, setLinkError] = useState("");
  const [servicesError, setServicesError] = useState("");
  const [shownInError, setShownInError] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("");
  const [stateError, setStateInError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [leadSourceError, setLeadSourceError] = useState("");
  const [languageError, setLanguageError] = useState("");

  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState();
  const [managePageData, setManagePageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("token"));

  const navigate = useNavigate();

  let hasError = false;

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    axios
      .get(`${API_BASE_URL}/list-pages`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setManagePageData(response.data.result.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData, "form");

    if (!formData.name) {
      setNameError("Please Enter Name ");
      hasError = true;
    } else {
      setNameError("");
    }
    if (!formData.email_id) {
      setEmailIdError("Please enter Email ID");
      hasError = true;
    } else {
      setEmailIdError("");
    }
    if (!formData.phone_no) {
      hasError = true;
      setPhoneNoError("Please enter Phone number");
    } else {
      setPhoneNoError("");
    }
    if (!formData.state) {
      setStateInError("Please eselect state ");
      hasError = true;
    } else {
      setStateInError("");
    }

    if (!formData.subject) {
      setSubjectError("Please enter the description");
      hasError = true;
    } else {
      setSubjectError("");
    }

    if (!formData.language) {
      setLanguageError("Please select language");
      hasError = true;
    } else {
      setLanguageError("");
    }

    const form = new FormData();
    form.append("page_id", formData.name);
    form.append("title", formData.email_id);
    form.append("short_order", formData.phone_no);
    form.append("link", formData.state);
    form.append("description", formData.subject);
    form.append("banner", image);

    if (!hasError) {
      axios
        .post(`${API_BASE_URL}/create-banner`, form, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status === "200") {
            navigate("/crm/mangeadsbanner");
            toast.success("Added successfully");
          }
        })
        .catch((error) => {
          setPageError(error.response.data.result);
          console.log(error);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (name === "image") {
      setImage(event.target.files[0]);
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Add Enquiry </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/crm/manageenquiry"}>Manage Enquiry</Link>
                      </li>

                      <li className="breadcrumb-item active">Add Enquiry</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Add Enquiry</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Name
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="name"
                                  name="name"
                                  type="text"
                                  className={
                                    nameError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Name..."
                                  onChange={handleInputChange}
                                />
                                {nameError && (
                                  <div className="text-danger">{nameError}</div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Email ID
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="email_id"
                                  name="email_id"
                                  type="text"
                                  className={
                                    emailIdError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Name..."
                                  onChange={handleInputChange}
                                />
                                {emailIdError && (
                                  <div className="text-danger">
                                    {emailIdError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Phone No.
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="phone_no"
                                  name="phone_no"
                                  type="text"
                                  className={
                                    phoneNoError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Phone No."
                                  onChange={handleInputChange}
                                />
                                {phoneNoError && (
                                  <div className="text-danger">
                                    {phoneNoError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                State
                              </label>
                              <div className="col-lg-10">
                                <select
                                  className={
                                    stateError
                                      ? " form-select is-invalid"
                                      : "form-control "
                                  }
                                  styles="max-width: 90px;"
                                  name="state"
                                  onChange={handleInputChange}
                                >
                                  <option value="">Select an option</option>
                                  {managePageData?.map((item) => (
                                    <option value={item.id}>
                                      {item.page_name}
                                    </option>
                                  ))}
                                </select>
                                {stateError && (
                                  <div className="text-danger">
                                    {stateError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Subject
                              </label>
                              <div className="col-lg-10">
                                <select
                                  className={
                                    subjectError
                                      ? " form-select is-invalid"
                                      : "form-control "
                                  }
                                  styles="max-width: 90px;"
                                  name="subject"
                                  onChange={handleInputChange}
                                >
                                  <option value="">Select an option</option>
                                  {managePageData?.map((item) => (
                                    <option value={item.id}>
                                      {item.page_name}
                                    </option>
                                  ))}
                                </select>
                                {subjectError && (
                                  <div className="text-danger">
                                    {subjectError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Lead Source
                              </label>
                              <div className="col-lg-10">
                                <select
                                  className={
                                    leadSourceError
                                      ? " form-select is-invalid"
                                      : "form-control "
                                  }
                                  styles="max-width: 90px;"
                                  name="lead_source"
                                  onChange={handleInputChange}
                                >
                                  <option value="">Select an option</option>
                                  {managePageData?.map((item) => (
                                    <option value={item.id}>
                                      {item.page_name}
                                    </option>
                                  ))}
                                </select>
                                {leadSourceError && (
                                  <div className="text-danger">
                                    {leadSourceError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Language
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="language"
                                  name="language"
                                  type="text"
                                  className={
                                    languageError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Name..."
                                  onChange={handleInputChange}
                                />
                                {languageError && (
                                  <div className="text-danger">
                                    {languageError}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}
                            <button type="submit" className="btn btn-primary">
                              Add Enquiry
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEnquiry;
