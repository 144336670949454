import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import Navbar from "../../Layouts/Navbar";
import Footer from "../../Layouts/Footer";
import LeftSideBar from "../../Layouts/LeftSideBar";
import { toast } from "react-toastify";
import CustomerProfile from './customerprofile';
import date from 'date-and-time';

const ManageCustomer = () => {
  const [listPageData, setListPageData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [status, setStatus] = useState(0);
  const [isView,setIsView]=useState(false);
  const [viewCompanyData, setViewCompanyData] = useState(null);
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadContent();
  }, []);

  const loadContent = async () => {
    const company_data = await axios.get(`${API_BASE_URL}/manage-company`, {headers: {Authorization: `Bearer ${token}`}});
    const js_data = JSON.parse(JSON.stringify(company_data.data));
    setListPageData(js_data.result.company_list);
      
  };

  /*-------------handle view customers------------*/

  const handleViewCustomer=async(id)=>{   
     try{ 
          setIsView(true);
          const frm = {"id":id};
          const view_company_data = await axios.get(`${API_BASE_URL}/manage-view-company?id=`+`${id}`, {headers: {Authorization: `Bearer ${token}`}});
          let js_data = JSON.parse(JSON.stringify(view_company_data.data));
          setViewCompanyData(js_data.result.company_list);
      }catch(e){
        return false;
      }
  }
 

  return (
    <>

         
      <div className="main-content">
        <div className="page-content">
           {
              isView!=false?<><CustomerProfile setIsView={setIsView} viewCompanyData={viewCompanyData}/></>:<>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Manage Customer </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item active">Manage Customer</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
           
                   <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">
                      Customers Details ({listPageData?.length})
                    </h4>
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th className="align-middle">Company Name</th>
                            <th className="align-middle">Director Name</th>
                            <th className="align-middle">Mobile</th>
                            <th className="align-middle">Email</th> 
                            <th className="align-middle">Plan Info</th>                                                             
                            <th className="align-middle">Status</th>
                            <th className="align-middle">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listPageData?.map((item) => (
                            <>
                              <tr>
                                <td>{item.company_name}</td>
                                <td>{item.dir_name}</td>
                                <td>{item.mobile}</td>
                                <td>
                                  {item.email}
                                  
                                </td>
                                <td> 
                                  <p><strong>Plan : </strong> {item.plan_details!=null ? item.plan_details.title:null}</p>                                
                                  <p><strong>Date :</strong> {item.transaction_details !=null ? item.transaction_details.created_at : null} </p>
                                  <p><strong>Amount :</strong> {item.transaction_details !=null ? item.transaction_details.amount/100 : null} </p>
                                  <p><strong>Payment Id :</strong> {item.transaction_details !=null ? item.transaction_details.transaction_id : null} </p>
                                </td>                             
                               
                                <td>
                                  {item.is_install == 1 ? (
                                    <span className="badge badge-pill badge-soft-success font-size-11">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge badge-pill badge-soft-danger font-size-11">
                                      Inactive
                                    </span>
                                  ) }
                                </td>

                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-success btn-sm btn-rounded waves-effect waves-light ms-2"
                                    onClick={() => {
                                      handleViewCustomer(item.id);
                                    }}
                                  >
                                    <i className="fa fa-eye"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-warning btn-sm btn-rounded waves-effect waves-light ms-2"
                                    
                                  >
                                    <i className="fa fa-ban"></i>
                                  </button>                                  
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
               

          </div>
            </>
            } 
        </div>
      </div>
    </>
  );
};

export default ManageCustomer;
