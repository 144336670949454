import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { API_BASE_URL, API_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";

const AddClient = () => {
  const [formData, setFormData] = useState({
    client_name: "",
    logo: "",
  });

  const { client_name, logo } = formData;

  const [pageError, setPageError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [nameError, setNameError] = useState("");
  const [shortOrderError, setShortOrderError] = useState("");
  const [linkError, setLinkError] = useState("");
  const [servicesError, setServicesError] = useState("");
  const [shownInError, setShownInError] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState();
  const [managePageData, setManagePageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [show, setShow] = useState(true);

  const navigate = useNavigate();

  const { id } = useParams();

  let hasError = false;

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if (id) {
      ViewClient();
    }
  }, []);

  const ViewClient = async () => {
    const res = await axios.get(`${API_BASE_URL}/view-client/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data.status == 200) {
      setFormData({
        ...formData,
        client_name: res.data.result.client_name,
        logo: res.data.result.logo,
      });
      setImagePreview(res.data.result.logo);
    }
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData, "form");

    if (!formData.client_name) {
      setNameError("Please enter name");
      hasError = true;
    } else {
      setNameError("");
    }
    if (!formData.logo) {
      setImageError("Please select the image");
      hasError = true;
    } else {
      setImageError("");
    }
    const form = new FormData();
    form.append("client_name", formData.client_name);
    form.append("logo", image);

    if (!hasError) {
      if (!id) {
        axios
          .post(`${API_BASE_URL}/add-client`, form, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log(response.data.status);
            if (response.data.status === "200") {
              navigate("/crm/manageclient");
              toast.success("Added successfully");
            }
          })
          .catch((error) => {
            setPageError(error.response.data.result);
            console.log(error);
          });
      } else {
        const data = new FormData();
        data.append("client_name", formData.client_name);
        data.append("logo", image);
        data.append("id", id);
        axios
          .post(`${API_BASE_URL}/update-client`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log(response.data.status);
            if (response.data.status === "200") {
              navigate("/crm/manageclient");
              toast.success("Added successfully");
            }
          })
          .catch((error) => {
            setPageError(error.response.data.result);
            console.log(error);
          });
      }
    }
  };

  const handleInputChange = (event) => {
    console.log(formData, "form");
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (name === "logo") {
      setShow(false);
      setImage(event.target.files[0]);
    }
  };

  const handleImageChange = (e) => {
    setShow(false);
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Add Client </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/crm/mangeadsbanner"}>Manage Client</Link>
                      </li>

                      <li className="breadcrumb-item active">Add Client</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Add Client</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Name
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="client_name"
                                  type="text"
                                  value={client_name}
                                  className={
                                    nameError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Name"
                                  onChange={handleInputChange}
                                />
                                {nameError && (
                                  <div className="text-danger">{nameError}</div>
                                )}
                              </div>
                            </div>
                            <div className="inner-repeater mb-4">
                              <div
                                data-repeater-list="inner-group"
                                className="inner form-group mb-0 row"
                              >
                                <label className="col-form-label col-lg-2">
                                  Image
                                </label>
                                <div
                                  data-repeater-item
                                  className="inner col-lg-10 ms-md-auto"
                                >
                                  <div className="mb-3 row align-items-center">
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-md-0">
                                        <input
                                          className={
                                            imageError
                                              ? "form-control is-invalid"
                                              : "form-control "
                                          }
                                          name="logo"
                                          type="file"
                                          accept="image/*"
                                          onChange={(e) => {
                                            handleImageChange(e);
                                            handleInputChange(e);
                                          }}
                                        />
                                        {imageError && (
                                          <div className="text-danger">
                                            {imageError}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-20">
                                        <div>
                                          {imagePreview && (
                                            <>
                                              {show === true ? (
                                                <img
                                                  src={`${API_URL}${logo}`}
                                                  alt="Preview"
                                                  width="200"
                                                  height="200"
                                                />
                                              ) : (
                                                <img
                                                  src={imagePreview}
                                                  alt="Preview"
                                                  width="200"
                                                  height="200"
                                                />
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}
                            <button type="submit" className="btn btn-primary">
                              Add Client
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddClient;
