import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Navbar from "./components/Layouts/Navbar";
import LeftSideBar from "./components/Layouts/LeftSideBar";
import ManageContentPage from "./components/cms/managecontentpage/ManageContentPage";
import ManageSlider from "./components/cms/manageSlider/ManageSlider";
import Footer from "./components/Layouts/Footer";
import AddContentPage from "./components/cms/managecontentpage/AddContentPage";
import AddSlider from "./components/cms/manageSlider/AddSlider";
import ManageSeoPage from "./components/cms/manageseopage/ManageSeoPage";
import ManageTestimonials from "./components/cms/managetestimonials/ManageTestimonials";
import AddSeoPage from "./components/cms/manageseopage/AddSeoPage";
import AddNewTestimonials from "./components/cms/managetestimonials/AddNewTestimonials";
import ManageAddBanner from "./components/cms/manageadsbanner/ManageAdsBanner";
import AddNewBanner from "./components/cms/manageadsbanner/AddNewBanner";
import ManagePricingPlans from "./components/pricingplans/pricingplans/ManagePricingPlans";
import AddPricingPlan from "./components/pricingplans/pricingplans/AddPricingPlan";
import EditContentPage from "./components/cms/managecontentpage/EditContentPage";
import EditSlider from "./components/cms/manageSlider/EditSlider";
import EditSeoPage from "./components/cms/manageseopage/EditSeoPage";
import EditTestimonials from "./components/cms/managetestimonials/EditTestimonials";
import EditAdsBanner from "./components/cms/manageadsbanner/EditAdsBanner";
import EditPricingPlan from "./components/pricingplans/pricingplans/EditPricingPlan";
import AddFaq from "./components/cms/faq/AddFaq";
import ManageFaqs from "./components/cms/faq/ManageFaqs";
import EditFaq from "./components/cms/faq/EditFaq";
import Login from "./components/Login/Login";
import ManagePage from "./components/cms/managepage/ManagePage";
import AddPage from "./components/cms/managepage/AddPage";
import EditPage from "./components/cms/managepage/EditPage";
import ManageLogoPage from "./components/cms/logo/ManageLogoPage";
import AddLogoPage from "./components/cms/logo/AddLogo";
import Dashboard from "./components/Dashboard";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFoundPage from "./components/cms/NotFoundPage";
import ManageSocialMedia from "./components/cms/mangesocialmedia/MangeSocialMedia";
import AddSocials from "./components/cms/mangesocialmedia/AddSocials";
import EditSocials from "./components/cms/mangesocialmedia/EditSocials";
import ManageIndustry from "./components/cms/managerIndustry/ManageIndustry";
import AddIndustry from "./components/cms/managerIndustry/AddIndustry";
import EditIndustry from "./components/cms/managerIndustry/EditIndustry";
import ManageEnquiry from "./components/cms/manageenquiry/manageenquiry";
import PositiveEnquiry from "./components/cms/manageenquiry/positiveenquiry";
import NegativeEnquiry from "./components/cms/manageenquiry/negativeenquiry";
import AddEnquiry from "./components/cms/manageenquiry/addenquiry";
import ManageSubscribe from "./components/cms/managesubscribe/managesubscribe";
import ManageCompanyProfile from "./components/cms/manageconpanyprofile/managecompanyprofile";
import AddCompanyProfile from "./components/cms/manageconpanyprofile/addcompanyprofile";
import ManageClient from "./components/cms/manageclient/manageclient";
import AddClient from "./components/cms/manageclient/addClient";
import CompanyInformation from "./components/cms/companyInformation/companyinformation";
import ManageCustomer from "./components/cms/managecustomer/managecustomer";
import {
  isLoggedIn,
  loginFailure,
  loginRequest,
  loginSuccess,
  selectUser,
} from "../src/components/store/auth/authSlice";

import { persistor } from "../src/components/store/store";

import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  const user = useSelector(selectUser);
  const loggedIn = useSelector(isLoggedIn);

  const [token, setToken] = useState(localStorage.getItem("token"));
  const [islogin, setIslogin] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    let path = window.location.pathname;
    if (token === null) {
      setIslogin(false);
      navigate("/crm");
    } else {
      setIslogin(true);
    }
  }, []);
  return (
    <div>
      <>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer />

          {!loggedIn ? (
            <>
              {" "}
              <Routes>
                <Route exact path="/crm" element={<Login />} />
              </Routes>
            </>
          ) : (
            <>
              <Navbar />
              <LeftSideBar />
              <Footer />
              <Routes>
                <Route path="/crm/home" element={<Dashboard />} />
                <Route path="/crm/managelogo" element={<ManageLogoPage />} />
                <Route
                  path="/crm/managelogo/addlogo"
                  element={<AddLogoPage />}
                />
                <Route path="/crm/managepage" element={<ManagePage />} />
                <Route
                  path="/crm/managepage/editpage/:id"
                  element={<EditPage />}
                />
                <Route path="/crm/managepage/addpage" element={<AddPage />} />
                <Route
                  path="/crm/managecontentpage"
                  element={<ManageContentPage />}
                />
                <Route
                  path="/crm/managecontentpage/addcontentpage"
                  element={<AddContentPage />}
                />
                <Route
                  path="/crm/managecontentpage/editcontentpage/:id"
                  element={<EditContentPage />}
                />
                <Route path="/crm/manageslider" element={<ManageSlider />} />
                <Route
                  path="/crm/manageslider/addnewslider"
                  element={<AddSlider />}
                />
                <Route
                  path="/crm/manageslider/editslider"
                  element={<EditSlider />}
                />
                <Route path="/crm/manageseopage" element={<ManageSeoPage />} />
                <Route
                  path="/crm/manageseopage/addseopage"
                  element={<AddSeoPage />}
                />
                <Route
                  path="/crm/manageseopage/editseopage/:id"
                  element={<EditSeoPage />}
                />
                <Route
                  path="/crm/managetestimonials"
                  element={<ManageTestimonials />}
                />
                <Route
                  path="/crm/managetestimonials/addnewtestimonials"
                  element={<AddNewTestimonials />}
                />
                <Route
                  path="/crm/managetestimonials/edittestimonials/:id"
                  element={<EditTestimonials />}
                />
                <Route
                  path="/crm/mangeadsbanner"
                  element={<ManageAddBanner />}
                />
                <Route
                  path="/crm/mangeadsbanner/addnewbanner"
                  element={<AddNewBanner />}
                />
                <Route
                  path="/crm/mangeadsbanner/editadsbanner/:id"
                  element={<EditAdsBanner />}
                />
                <Route
                  path="/crm/managepricingplans"
                  element={<ManagePricingPlans />}
                />
                <Route
                  path="/crm/managepricingplans/addpricingplans"
                  element={<AddPricingPlan />}
                />
                <Route
                  path="/crm/managepricingplans/editpricingplans"
                  element={<EditPricingPlan />}
                />
                <Route path="/crm/managefaq" element={<ManageFaqs />} />
                <Route path="/crm/managefaq/addfaq" element={<AddFaq />} />
                <Route
                  path="/crm/managefaq/editfaq/:id"
                  element={<EditFaq />}
                />
                <Route
                  path="/crm/managesocialmedia"
                  element={<ManageSocialMedia />}
                />
                <Route
                  path="/crm/managesocialmedia/addsocials"
                  element={<AddSocials />}
                />
                <Route
                  path="/crm/managesocialmedia/editsocials/:id"
                  element={<EditSocials />}
                />
                <Route
                  path="/crm/manageindustry"
                  element={<ManageIndustry />}
                />
                <Route
                  path="/crm/manageindustry/addindustry"
                  element={<AddIndustry />}
                />
                <Route
                  path="/crm/manageindustry/editindustry/:id"
                  element={<EditIndustry />}
                />
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/crm/manageenquiry" element={<ManageEnquiry />} />

                <Route
                  path="/crm/manageenquiry/positive-enquiry"
                  element={<PositiveEnquiry />}
                />

                <Route
                  path="/crm/manageenquiry/negative-enquiry"
                  element={<NegativeEnquiry />}
                />

                <Route
                  path="/crm/manageenquiry/addenquiry"
                  element={<AddEnquiry />}
                />

                <Route
                  path="/crm/managesubscribe"
                  element={<ManageSubscribe />}
                />

                <Route
                  path="/crm/managecompanyprofile"
                  element={<ManageCompanyProfile />}
                />

                <Route
                  path="/crm/managecompanyprofile/add-company-profile"
                  element={<AddCompanyProfile />}
                />
                <Route
                  path="/crm/companyinformation"
                  element={<CompanyInformation />}
                />
                <Route
                  path="/crm/managecompanyprofile/add-company-profile/:id"
                  element={<AddCompanyProfile />}
                />

                <Route path="/crm/manageclient" element={<ManageClient />} />

                <Route
                  path="/crm/manageclient/add-client"
                  element={<AddClient />}
                />
                <Route
                  path="/crm/manageclient/edit-client/:id"
                  element={<AddClient />}
                />
                <Route
                  path="/crm/manage-customer"
                  element={<ManageCustomer />}
                />
              </Routes>
            </>
          )}
        </PersistGate>
      </>
    </div>
  );
}

export default App;
