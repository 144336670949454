import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL, API_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";

const EditNewTestimonials = () => {
  const [formData, setFormData] = useState({
    pageurl: "",
    name: "",
    rating: "",
    metakeywords: "",
    description: "",
    cityandcountry: "",
    image_url: "",
  });
  const [pageError, setPageError] = useState("");
  const [show, setShow] = useState(true);

  const navigate = useNavigate();
  const {
    pageurl,
    name,
    rating,
    metakeywords,
    description,
    cityandcountry,
    image_url,
  } = formData;
  const { id } = useParams();

  const [image, setImage] = useState("");
  const [managePageData, setManagePageData] = useState();
  const [pageurlError, setPageurlError] = useState("");
  const [pageNameError, setPageNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [ratingError, setRatingError] = useState("");
  const [cityAndCountryError, setCityAndCountry] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    setToken(localStorage.getItem("token"));

    ViewPage();
  }, []);

  const ViewPage = async () => {
    const res = await axios.get(`${API_BASE_URL}/view-testimonial/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.data.status == 200) {
      setFormData({
        ...formData,
        pageurl: res.data.result.url,
        name: res.data.result.name,
        rating: res.data.result.rating,
        metakeywords: res.data.result.header_footer,
        description: res.data.result.description,
        cityandcountry: res.data.result.city_country,
      });
      setImagePreview(res.data.result.image);
    }
  };
  let hasError = false;
  const handleSubmit = (event) => {
    event.preventDefault();
    // if (!formData.pageurl) {
    //   setPageurlError("Please enter page url");
    //   hasError = true;
    // } else {
    //   setPageurlError("");
    // }
    // if (!formData.pagename) {
    //   setPageNameError("Please enter pagename");
    //   hasError = true;
    // } else {
    //   setPageNameError("");
    // }
    if (!formData.rating) {
      setRatingError("Please enter rating ");
      hasError = true;
    } else {
      setRatingError("");
    }

    if (!formData.description) {
      setDescriptionError("Please enter the description");
      hasError = true;
    } else {
      setDescriptionError("");
    }
    if (!formData.cityandcountry) {
      setCityAndCountry("Please enter the city");
      hasError = true;
    } else {
      setCityAndCountry("");
    }
    // if (!formData.image) {
    //   setImageError("Please select the image");
    //   hasError = true;
    // } else {
    //   setImageError("");
    // }

    const form = new FormData();
    form.append("name", formData.name);
    form.append("city_country", formData.cityandcountry);
    form.append("url", formData.pageurl);
    form.append("rating", formData.rating);
    form.append("description", formData.description);
    form.append("testimonial", image);
    form.append("id", id);
    if (!hasError) {
      axios
        .post(`${API_BASE_URL}/update-testimonial`, form, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status === "200") {
            navigate("/crm/managetestimonials");
            toast.success("Edited successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          setPageError(error.response.data.result);
        });
    }
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (name === "image") {
      setShow(false);
      setImage(event.target.files[0]);
    }
  };
  const handleImageChange = (e) => {
    setShow(false);
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">
                    Edit Testimonials Page
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/crm/managetestimonials"}>
                          Manage Testimoials Page
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Edit Testimonials Page
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">
                        Edit Testimonials Page
                      </h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Name
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="name"
                                  type="text"
                                  value={name}
                                  className={
                                    pageNameError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter name..."
                                  onChange={handleInputChange}
                                />
                                {pageNameError && (
                                  <div className="text-danger">
                                    {pageNameError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                City and country
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="cityandcountry"
                                  type="text"
                                  value={cityandcountry}
                                  className={
                                    cityAndCountryError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter city and country url..."
                                  onChange={handleInputChange}
                                />
                                {cityAndCountryError && (
                                  <div className="text-danger">
                                    {cityAndCountryError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Page url
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="pageurl"
                                  type="text"
                                  value={pageurl}
                                  className={
                                    pageurlError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter page url..."
                                  onChange={handleInputChange}
                                />
                                {pageurlError && (
                                  <div className="text-danger">
                                    {pageurlError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Ratings
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="rating"
                                  type="text"
                                  value={rating}
                                  className={
                                    ratingError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter rating..."
                                  onChange={handleInputChange}
                                />
                                {ratingError && (
                                  <div className="text-danger">
                                    {ratingError}
                                  </div>
                                )}
                              </div>
                            </div>

                            {description && (
                              <div className="form-group row mb-4">
                                <label className="col-form-label col-lg-2">
                                  Description
                                </label>
                                <div className="col-lg-10">
                                  <Editor
                                    onEditorChange={
                                      handleEditorChangeDescription
                                    }
                                    init={{
                                      plugins:
                                        "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                      table_default_attributes: {
                                        border: "5",
                                      },
                                    }}
                                    initialValue={description}
                                  />
                                  {descriptionError && (
                                    <div className="text-danger">
                                      {descriptionError}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            <div className="inner-repeater mb-4">
                              <div
                                data-repeater-list="inner-group"
                                className="inner form-group mb-0 row"
                              >
                                <label className="col-form-label col-lg-2">
                                  Image
                                </label>
                                <div
                                  data-repeater-item
                                  className="inner col-lg-10 ms-md-auto"
                                >
                                  <div className="mb-3 row align-items-center">
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-md-0">
                                        <input
                                          className={
                                            imageError
                                              ? "form-control is-invalid"
                                              : "form-control "
                                          }
                                          name="image"
                                          type="file"
                                          accept="image/*"
                                          onChange={(e) => {
                                            handleImageChange(e);
                                            handleInputChange(e);
                                          }}
                                        />
                                        {imageError && (
                                          <div className="text-danger">
                                            {imageError}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-20">
                                        <div>
                                          {imagePreview && (
                                            <>
                                              {show === true ? (
                                                <img
                                                  src={`${API_URL}${imagePreview}`}
                                                  alt="Preview"
                                                  width="200"
                                                  height="200"
                                                />
                                              ) : (
                                                <img
                                                  src={imagePreview}
                                                  alt="Preview"
                                                  width="200"
                                                  height="200"
                                                />
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            <button type="submit" className="btn btn-primary">
                              Edit Content Page
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditNewTestimonials;
