import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";

const AddSeoPage = () => {
  const [formData, setFormData] = useState({
    pageId: "",
    pageurl: "",
    pagename: "",
    metatitle: "",
    metakeywords: "",
    description: "",
  });

  const { pageurl, pagename, metatitle, metakeywords, description, pageId } =
    formData;
  const [pageError, setPageError] = useState("");
  const [pageurlError, setPageurlError] = useState("");
  const [pageNameError, setPageNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [metaTitleError, setMetaTitleError] = useState("");
  const [metaKeywordError, setMetaKeywordsError] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [managePageData, setManagePageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const navigate = useNavigate();

  let hasError = false;

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    ListPage();
  }, []);

  const ListPage = async () => {
    axios
      .get(`${API_BASE_URL}/list-pages`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        setManagePageData(response.data.result.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData, "form");
    if (!formData.pageurl) {
      setPageurlError("Please enter page url");
      hasError = true;
    } else {
      setPageurlError("");
    }

    if (!formData.pagename) {
      setPageNameError("Please enter pagename");
      hasError = true;
    } else {
      setPageNameError("");
    }
    if (!formData.metatitle) {
      setMetaTitleError("Please enter meta title ");
      hasError = true;
    } else {
      setMetaTitleError("");
    }
    // if (!formData.metakeywords) {
    //   setMetaKeywordsError("Please enter meta keywords ");
    //   hasError = true;
    // } else {
    //   setMetaKeywordsError("");
    // }

    // if (!formData.description) {
    //   setDescriptionError("Please enter the description");
    //   hasError = true;
    // } else {
    //   setDescriptionError("");
    // }

    if (!hasError) {
      axios
        .post(
          `${API_BASE_URL}/create-seo-page`,
          {
            page_name: pagename,
            page_url: pageurl,
            meta_title: metatitle,
            meta_keywords: metakeywords,
            meta_description: description,
            page_id: pageId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status === "200") {
            navigate("/crm/manageseopage");
            toast.success("Added successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          setPageError(error.response.data.result);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    console.log(event.target, "mame");
    console.log(name, value, "mame");
    console.log(formData, "valueeeeeeee");

    if (name === "pagename") {
      const selectedOption = event.target.options[event.target.selectedIndex];
      const slug = selectedOption.dataset.slug;
      const Name = selectedOption.dataset.name;
      console.log(name, "selectedOption");
      setFormData({
        ...formData,
        pageId: value,
        [name]: Name,
        pageurl: slug,
      });
    }
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Add SEO Page </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/crm/manageseopage"}>Manage SEO Page</Link>
                      </li>
                      <li className="breadcrumb-item active">Add SEO Page</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Add SEO</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Page Name
                              </label>
                              <div className="col-lg-10">
                                <select
                                  className={
                                    pageNameError
                                      ? " form-select is-invalid"
                                      : "form-control "
                                  }
                                  styles="max-width: 90px;"
                                  name="pagename"
                                  // value={pagename}
                                  onChange={(e) => handleInputChange(e)}
                                >
                                  <option value="0">None of options</option>
                                  {managePageData?.map((item) => (
                                    <option
                                      value={item.id}
                                      data-slug={
                                        item.page_slug == ""
                                          ? "/"
                                          : item.page_slug
                                      }
                                      data-name={
                                        item.page_name == ""
                                          ? "/"
                                          : item.page_name
                                      }
                                    >
                                      {item.page_name}
                                    </option>
                                  ))}
                                </select>
                                {pageNameError && (
                                  <div className="text-danger">
                                    {pageNameError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Page url
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="pageurl"
                                  type="text"
                                  value={pageurl}
                                  className={
                                    pageurlError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter page url..."
                                  onChange={handleInputChange}
                                />
                                {pageurlError && (
                                  <div className="text-danger">
                                    {pageurlError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Meta title
                              </label>
                              <div className="col-lg-10">
                                <textarea
                                  id="taskname"
                                  name="metatitle"
                                  type="text"
                                  className={
                                    metaTitleError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter meta title..."
                                  onChange={handleInputChange}
                                />
                                {metaTitleError && (
                                  <div className="text-danger">
                                    {metaTitleError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Meta keywords
                              </label>
                              <div className="col-lg-10">
                                <textarea
                                  id="taskname"
                                  name="metakeywords"
                                  type="text"
                                  className={
                                    metaKeywordError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter meta keywords..."
                                  onChange={handleInputChange}
                                />
                                {metaKeywordError && (
                                  <div className="text-danger">
                                    {metaKeywordError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Description
                              </label>
                              <div className="col-lg-10">
                                <textarea
                                  id="taskname"
                                  name="description"
                                  type="text"
                                  className={
                                    descriptionError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter meta keywords..."
                                  onChange={handleInputChange}
                                />
                                {/* <Editor
                                  onEditorChange={handleEditorChangeDescription}
                                  init={{
                                    plugins:
                                      "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    table_default_attributes: {
                                      border: "5",
                                    },
                                  }}
                                /> */}
                                {descriptionError && (
                                  <div className="text-danger">
                                    {descriptionError}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}
                            <button type="submit" className="btn btn-primary">
                              Add SEO Page
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSeoPage;
