import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { API_BASE_URL } from "../config";

import {
  loginFailure,
  loginRequest,
  loginSuccess,
} from "../store/auth/authSlice";
import { useDispatch } from "react-redux";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [pageError, setPageError] = useState("");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    dispatch(loginRequest());
    event.preventDefault();
    if (!username) {
      setUsernameError("Please enter your username");
    } else {
      setUsernameError("");
    }
    if (!password) {
      setPasswordError("please enter your password");
    } else {
      setPasswordError("");
    }

    await axios
      .post(`${API_BASE_URL}/login`, {
        email: username,
        password: password,
      })
      .then(function (response) {
        console.log(response.data);
        if (response.data.status === "200") {
          localStorage.setItem("token", response.data.token);
          dispatch(loginSuccess(response.data.result));
          navigate("/crm/home");
        }
      })
      .catch(function (error) {
        console.log(error);
        dispatch(loginFailure("An error occurred"));
        setPageError(error.response.data.result);
      });
  };

  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card overflow-hidden">
                <div className="bg-primary bg-soft">
                  {/* <div className="row">
                    <div className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                      </div>
                    </div>
                    <div className="col-5 align-self-end">
                      <img
                        src="assets/images/profile-img.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div> */}
                </div>
                <div className="card-body pt-0">
                  <div className="auth-logo">
                    <div className="col-md-12 text-center mt-3 mb-3">
                      <img
                        src="https://closecall.in/corpbiz-call-api/public/uploads/logo-1683105181192.png"
                        alt=""
                        height="100%"
                      />
                    </div>
                  </div>
                  <div className="p-2">
                    <form className="form-horizontal" onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="username" className="form-label">
                          Username
                        </label>
                        <input
                          type="text"
                          className={
                            usernameError
                              ? "form-control is-invalid"
                              : "form-control "
                          }
                          id="username"
                          name="name"
                          placeholder="Enter username"
                          value={username}
                          onChange={(event) => setUsername(event.target.value)}
                        />
                        {usernameError && (
                          <div className="text-danger">{usernameError}</div>
                        )}
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input
                          type="password"
                          className={
                            passwordError
                              ? "form-control is-invalid"
                              : "form-control "
                          }
                          placeholder="Enter password"
                          aria-label="Password"
                          aria-describedby="password-addon"
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                        />
                        {passwordError && (
                          <div className="text-danger">{passwordError}</div>
                        )}

                        {/* <button
                          className="btn btn-light "
                          type="button"
                          name="password"
                          id="password-addon"
                        >
                          <i className="mdi mdi-eye-outline"></i>
                        </button> */}
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="remember-check"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="remember-check"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        {pageError && (
                          <div className="alert alert-danger" role="alert">
                            {pageError}
                          </div>
                        )}
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
