import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import Navbar from "../../Layouts/Navbar";
import Footer from "../../Layouts/Footer";
import LeftSideBar from "../../Layouts/LeftSideBar";
import { toast } from "react-toastify";

const ManageCompanyProfile = () => {
  const [listPageData, setListPageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [status, setStatus] = useState(0);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadContent();
  }, []);

  const loadContent = async () => {
    await axios
      .get(`${API_BASE_URL}/list-company-profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        setListPageData(response.data.result.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (id) => {
    const result = window.confirm("Do you want to delete ?");

    if (result) {
      axios
        .delete(`${API_BASE_URL}/subscriber-delete/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === "200") {
            toast.success("deleted successfully");
            axios
              .get(`${API_BASE_URL}/subscriber-list`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((response) => {
                setListPageData(response.data.result.reverse());
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    }
  };

  const handleActive = async (id) => {
    const res = await axios.post(
      `${API_BASE_URL}/activate-page`,
      { status: status, id: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const response = JSON.parse(JSON.stringify(res));
    console.log(response);
    if (response.data.status === 200) {
      loadContent();
      if (status == 1) {
        toast.success("Active successfully");
      } else {
        toast.success("Inactive successfully");
      }
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">
                    Manage Company Profile
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Manage Company Profile
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-end">
              <div className="col-lg-12 mb-2 ">
                <Link
                  to={`/crm/managecompanyprofile/add-company-profile/${listPageData?.[0].id}`}
                  data-repeater-create
                  type="button"
                  className="btn btn-success inner"
                  value="Add Number"
                >
                  {listPageData?.length < 0 ? "Add" : "Edit"} Company Profile
                </Link>
              </div>

              <div className="col-lg-12 mb-2 ">
                {/* <Link
                                    to={`/crm/managecompanyprofile/add-company-profile/${item.id}`}
                                    type="button"
                                    className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                                  >
                                    Edit Content 
                                  </Link> */}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="p-3">
                    <h3 className="mb-0">Company Information </h3>
                  </div>
                  <hr />
                  <div className="card-body">
                    <div className="row ">
                      <div className="col-6">
                        <div className="row border-end">
                          <div className="col-4 fs-18  my-2">Mobile :</div>
                          <div className="col-8 my-2">
                            {listPageData?.[0].mobile}
                          </div>
                          <div className="col-4 fs-18  my-2">Email</div>
                          <div className="col-8 my-2">
                            {listPageData?.[0].email}
                          </div>
                          <div className="col-4 fs-18  my-2">Address</div>
                          <div className="col-8 my-2">
                            {listPageData?.[0].address}
                          </div>
                          <div className="col-4 fs-18  my-2">Email To</div>
                          <div className="col-8 my-2 ">
                            {listPageData?.[0].email_to}
                          </div>
                          <div className="col-4 fs-18  my-2 mt-4">
                            Head script
                          </div>
                          <div className="col-8 my-2 mt-4 ">
                            {listPageData?.[0].head_script}
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="row">
                          <div className="col-4 fs-18  my-2">Reply To</div>
                          <div className="col-8 my-2 ">
                            {listPageData?.[0].reply_to}
                          </div>
                          <div className="col-4 fs-18  my-2">Copyright</div>
                          <div className="col-8 my-2 ">
                            {listPageData?.[0].copy_right}
                          </div>
                          <div className="col-4 fs-18  my-2">
                            App Store Link
                          </div>
                          <div className="col-8 my-2 ">
                            {listPageData?.[0].app_store_link}
                          </div>
                          <div className="col-4 fs-18 ">Play Store Link</div>
                          <div className="col-8 my-2 ">
                            {listPageData?.[0].play_store_link}
                          </div>

                          <div className="col-4 fs-18 ">Body script</div>
                          <div className="col-8 my-2 ">
                            {listPageData?.[0].body_script}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageCompanyProfile;
